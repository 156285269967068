import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function AuthCheck({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const AuthToken = localStorage.getItem('AuthToken');
    const buyplanCheck = localStorage.getItem('buyplan');
    const userPlan = buyplanCheck ? JSON.parse(buyplanCheck) : null;
    const user = localStorage.getItem('user');
    const currentDate = new Date().getTime();
    const toDate = userPlan ? new Date(userPlan.to_date).getTime() : null;

    if (AuthToken && user) {
      if (!userPlan || userPlan == 'false') {
        navigate('/plan');
      } else if (userPlan.plan_id) {
        if (currentDate > toDate) {
          navigate('/plan');
        } else {
          setIsAuthenticated(true);
        }
      }
    } else {
      navigate('/login');
    }
  }, [navigate]);

  if (!isAuthenticated) {
    return null; // Render nothing or a loading spinner while authentication is checked
  }

  return <>{children}</>;
}

export default AuthCheck;
