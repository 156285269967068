import React from "react";

const Vision = () => {
  return (
    <div>
      <section class="vision-hero">
        <div class="container">
          <div class="row">
            <div class="col-md-7">
              <div class="vision-right">
                <h2>Our Vision</h2>
                <p>
                  "At Capovex Research and Analysis, our vision is to
                  revolutionize the financial landscape by setting the global
                  standard for integrity, innovation, and insight in financial
                  research.We envision a world where transformative financial
                  data empowers every investor, guiding their decisions for a
                  substantial impact. Committed to cutting-edge technology and
                  sustainability, we strive to lead the industry towards a
                  responsible future, becoming a beacon of knowledge and
                  reliability for global prosperity.
                </p>
              </div>
            </div>
            <div class="col-md-5">
              <div class="visionimg">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/visionbgnew.png`}
                  class="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="home-investment vision-col">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="home-investment-right">
                <h3>Global Standards for Integrity</h3>
                <p>
                  Driving trust and transparency in financial research, we
                  commit to the highest standards of integrity across all
                  interactions
                </p>
                <ul>
                  <li>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/check.svg`}
                        class="img-fluid"
                        alt=""
                      />
                    </span>
                    Uncompromising commitment to ethical practices
                  </li>
                  <li>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/check.svg`}
                        class="img-fluid"
                        alt=""
                      />
                    </span>
                    Transparency in our research, fostering trust and
                    reliability
                  </li>
                  <li>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/check.svg`}
                        class="img-fluid"
                        alt=""
                      />
                    </span>
                    Maintaining the highest standards of trustworthiness
                  </li>
                  <li>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/check.svg`}
                        class="img-fluid"
                        alt=""
                      />
                    </span>
                    Build long-term relationships based on honesty and integrity
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-6">
              <div class="home-investment-left">
                <img src={`${process.env.PUBLIC_URL}/assets/images/vision1.jpg`} class="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="home-investment visionwhite vision-col">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="home-investment-left">
                <img src={`${process.env.PUBLIC_URL}/assets/images/vision2.jpg`} class="img-fluid" alt="" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="home-investment-right">
                <h3>Innovative & Transformative Data</h3>
                <p>
                  We deliver data that transforms understanding, enabling
                  investors worldwide to make informed, impactful decisions
                </p>
                <ul>
                  <li>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/check.svg`}
                        class="img-fluid"
                        alt=""
                      />
                    </span>
                    AI and machine learning to provide insights
                  </li>
                  <li>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/check.svg`}
                        class="img-fluid"
                        alt=""
                      />
                    </span>
                    Empowering investors with data back decision-making
                  </li>
                  <li>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/check.svg`}
                        class="img-fluid"
                        alt=""
                      />
                    </span>
                    Real-time data updates to keep investors ahead
                  </li>
                  <li>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/check.svg`}
                        class="img-fluid"
                        alt=""
                      />
                    </span>
                    Tailoring our data and insights to meet the specific needs
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="home-investment vision-col">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="home-investment-right">
                <h3>Empowering Global Investors</h3>
                <p>
                  Capovex democratizes access to critical financial data,
                  empowering investors from all backgrounds to achieve
                  prosperity
                </p>
                <ul>
                  <li>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/check.svg`}
                        class="img-fluid"
                        alt=""
                      />
                    </span>
                    Inclusive Access regardless of location
                  </li>
                  <li>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/check.svg`}
                        class="img-fluid"
                        alt=""
                      />
                    </span>
                    Providing educational resources to help investors
                  </li>
                  <li>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/check.svg`}
                        class="img-fluid"
                        alt=""
                      />
                    </span>
                    Offering personalized support for research and analysis
                  </li>
                  <li>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/check.svg`}
                        class="img-fluid"
                        alt=""
                      />
                    </span>
                    Developing innovative tools and platforms that empower
                    investor
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-6">
              <div class="home-investment-left">
                <img src={`${process.env.PUBLIC_URL}/assets/images/vision3.jpg`} class="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="home-investment visionwhite vision-col">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="home-investment-left">
                <img src={`${process.env.PUBLIC_URL}/assets/images/vision4.jpg`} class="img-fluid" alt="" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="home-investment-right">
                <h3>Sustainability in Finance</h3>
                <p>
                  Leading with sustainable practices, we aim for a financial
                  industry that contributes positively to environmental
                  stewardship
                </p>
                <ul>
                  <li>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/check.svg`}
                        class="img-fluid"
                        alt=""
                      />
                    </span>
                    Incorporating sustainable practices in our operations
                  </li>
                  <li>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/check.svg`}
                        class="img-fluid"
                        alt=""
                      />
                    </span>
                    Promoting and supporting investments in sustainability
                  </li>
                  <li>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/check.svg`}
                        class="img-fluid"
                        alt=""
                      />
                    </span>
                    Focusing on long-term growth that benefits investors &
                    planet
                  </li>
                  <li>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/check.svg`}
                        class="img-fluid"
                        alt=""
                      />
                    </span>
                    Engaging in initiatives that support environment
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="counters-sec core-pillars">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="headingsec">
                <h2>Core Pillars of Capovex</h2>
                <p>
                  At the heart of Capovex lies a commitment to unparalleled
                  integrity, groundbreaking innovation, and a vision for a
                  sustainable and inclusive financial future
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="plansec-card">
                <span>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/visioncard1.svg`}
                    class="img-fluid"
                    alt=""
                  />
                </span>
                <h3>Integrity</h3>
                <p>
                  integrity is the cornerstone of everything we do. We ensure
                  that all our research is conducted with the highest standards
                  of honesty and ethical behavior, maintaining objectivity in
                  our analyses.
                </p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="plansec-card">
                <span>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/visioncard2.svg`}
                    class="img-fluid"
                    alt=""
                  />
                </span>
                <h3>Transparency</h3>
                <p>
                  We believe in complete transparency in our processes and
                  methodologies. Our clients deserve to understand not only what
                  recommendations are made, but also how and why they are made.
                </p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="plansec-card">
                <span>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/visioncard3.svg`}
                    class="img-fluid"
                    alt=""
                  />
                </span>
                <h3>Innovation</h3>
                <p>
                  We relentlessly pursue innovation in financial research. By
                  leveraging cutting-edge technologies and methodologies, we
                  continuously enhance our analytical capabilities to serve our
                  clients better.
                </p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="plansec-card">
                <span>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/visioncard4.svg`}
                    class="img-fluid"
                    alt=""
                  />
                </span>
                <h3>Client-Centric</h3>
                <p>
                  Our clients are at the heart of our business. We are dedicated
                  to understanding and meeting their unique needs, ensuring that
                  they receive personalized and effective solutions.
                </p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="plansec-card">
                <span>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/visioncard5.svg`}
                    class="img-fluid"
                    alt=""
                  />
                </span>
                <h3>Commitment</h3>
                <p>
                  Our commitment to excellence drives us to deliver only the
                  best in everything we do. From accuracy of our data to clarity
                  of our reports, excellence permeates every aspect of our
                  operations.
                </p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="plansec-card">
                <span>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/visioncard6.svg`}
                    class="img-fluid"
                    alt=""
                  />
                </span>
                <h3>Accessibility</h3>
                <p>
                  We strive to democratize access to financial information. Our
                  goal is to simplify financial data so that everyone, from
                  novice investors to professionals, can make informed
                  decisions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Vision;
