import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import apiFunctions from '../../Settings/Api'; // Replace with your actual API functions

const ContentDetail = () => {
  const { id } = useParams(); // Access the 'id' parameter from the URL
  const [contentDetail, setContentDetail] = useState(null);
  const [categoryNames, setCategoryNames] = useState([]);
  const [relatedContent, setRelatedContent] = useState([]);
  const [loading, setLoading] = useState(true);
  const ApibaseUrl = 'http://127.0.0.1:8000';
  const wordLimit = 500; // Adjust this limit as needed
  const [showFullContent, setShowFullContent] = useState(false); // State to track whether to show full content

  useEffect(() => {
    const fetchContentDetail = async () => {
      try {
        const response = await apiFunctions.getContentDetail({ id });
        setContentDetail(response.data);
        if (response.data?.category_names) {
          // Assuming category_names is an array
          setCategoryNames(response.data.category_names);
        }

        // Fetch related content based on category IDs
        const relatedResponse = await apiFunctions.getContentByCategoryId({
          CategoryId: response.data.category_id,
        });
        setRelatedContent(relatedResponse.data);
      } catch (error) {
        console.error('Error fetching content detail:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchContentDetail();
  }, [id]);

  // Ensure contentDetail is truthy before accessing image and other properties
  const ContentId = contentDetail?.id;
  const image = contentDetail?.image;
  const title = contentDetail?.title;
  const content = contentDetail?.content;
  const createdAt = contentDetail?.created_at;
  const AuthorName = `${contentDetail?.first_name} ${contentDetail?.last_name}`;

  const formatCreatedAt = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  // Function to count words in content
  const countWords = (text) => {
    if (!text) return 0;
    return text.split(/\s+/).length;
  };

  // Check if content exceeds word limit
  const isContentLong = countWords(content) > wordLimit;

  // Function to toggle showing full content
  const toggleShowFullContent = () => {
    setShowFullContent(!showFullContent);
  };

  return (
    <div>
      <section
        className="content-banner"
        style={{
          background: `url('${
            ApibaseUrl}/${image ? image : `${process.env.PUBLIC_URL}/assets/images/contentbg.jpg`
          }')`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="content-title">
                <h2>{title}</h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="contentnewpage">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="contentpage-design contentnewpage-left">
                {/* Render content with limited words */}
                <div dangerouslySetInnerHTML={{ __html: content?.split(/\s+/).slice(0, wordLimit).join(' ') }} />

                {/* Render Read More button conditionally */}
                {isContentLong && !showFullContent && (
                  <div className="contentnewpage-readmore">
                    <a href="javascript:void(0);" className="btn btn-info btndark" onClick={toggleShowFullContent}>
                      Read More{' '}
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/readmore.svg`}
                        className="img-fluid"
                        alt=""
                      />
                    </a>
                  </div>
                )}

                {/* Render full content if showFullContent is true */}
                {showFullContent && (
                  <div dangerouslySetInnerHTML={{ __html: content }} />
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div className="contentnewpage-right">
                <div className="contentnewpage-bedge">
                  <span>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/heart.svg`}
                      className="img-fluid"
                      alt=""
                    />
                    24.5k
                  </span>
                  <span>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/eye.svg`}
                      className="img-fluid"
                      alt=""
                    />
                    50k
                  </span>
                  <span>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/paperplane.svg`}
                      className="img-fluid"
                      alt=""
                    />
                    206
                  </span>
                </div>
                <ul className="contentnewpage-info">
                  <li>
                    <label>Publication Date</label>
                    <span>{formatCreatedAt(createdAt)}</span>
                  </li>
                  <li>
                    <label>Category</label>
                    <span>{categoryNames.join(', ')}</span>
                  </li>
                  <li>
                    <label>Reading Time</label>
                    <span>10 Min</span>
                  </li>
                  <li>
                    <label>Author Name</label>
                    <span>{AuthorName}</span>
                  </li>
                </ul>
                <h3>Table of Contents</h3>
                <div className="contentnewpage-list">
                  <ul>
                    <li>
                      <a href="#">Introduction</a>
                    </li>
                    <li>
                      <a href="#">AI in Diagnostic Imaging</a>
                    </li>
                    <li>
                      <a href="#">
                        Predictive Analytics and Disease Prevention
                      </a>
                    </li>
                    <li>
                      <a href="#">Personalized Treatment Plans</a>
                    </li>
                    <li>
                      <a href="#">Drug Discovery and Research</a>
                    </li>
                    <li>
                      <a href="#">AI in Telemedicine</a>
                    </li>
                    <li>
                      <a href="#">Ethical Considerations</a>
                    </li>
                    <li>
                      <a href="#">The Future of AI in Healthcare</a>
                    </li>
                    <li>
                      <a href="#">Conclusion</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            {relatedContent.map((relatedItem) => (
              relatedItem.id !== ContentId && (
                <div className="col-md-4" key={relatedItem.id}>
                  <div className="contentnew-card">
                    <Link
                      to={`/content-details/${relatedItem.id}`}
                      className="contentnew-cardimg"
                    >
                      <img
                        src={`${ApibaseUrl}/${relatedItem.image ? relatedItem.image : `${process.env.PUBLIC_URL}/assets/images/contentnew-card1.jpg`}`}
                        className="img-fluid"
                        alt=""
                      />
                    </Link>
                    <h3>
                      <Link to={`/content-details/${relatedItem.id}`}>
                        {relatedItem.title}
                      </Link>
                    </h3>
                    <div className="contentnewpage-bedge">
                      <span>
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/heart-o.svg`}
                          className="img-fluid"
                          alt=""
                        />
                        2.2k
                      </span>
                      <span>
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/paperplane.svg`}
                          className="img-fluid"
                          alt=""
                        />
                        60
                      </span>
                      <Link
                        to={`/content-details/${relatedItem.id}`}
                        className="btn btn-info btndark"
                      >
                        Read More{' '}
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/Icontopright.svg`}
                          className="img-fluid"
                          alt=""
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              )
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContentDetail;
