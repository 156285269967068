import React, { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import apiFunctions from '../../Settings/Api';
import Sidebar from '../Dashboard/Sidebar';
import AuthCheck from '../../Auth/AuthCheck';

const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const toggleShowCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const toggleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      const errorData = { errors: { new_password: ["The new password field confirmation does not match."] }, message: "The new password field confirmation does not match." };
      handleBackendError(errorData);
      return;
    }
  
    try {
      const AuthToken = localStorage.getItem('AuthToken');
      const response = await apiFunctions.changePassword(AuthToken, currentPassword, newPassword);
      if (response) {
        setSuccessMessage(response.message);
        clearFields();
        setTimeout(() => {
          setSuccessMessage('');
        }, 2000); // Hide success message after 2 seconds
      } else {
        throw new Error('Failed to change password. Please try again.');
      }
    } catch (error) {
      handleBackendError(error);
    }
  };

  const handleBackendError = (errorData) => {
    if (errorData.errors && errorData.errors.new_password) {
      setErrorMessage(errorData.errors.new_password[0]);
    } else if (errorData.message) {
      setErrorMessage(errorData.message);
    } else {
      setErrorMessage('Failed to change password. Please try again.');
    }
    setTimeout(() => {
      setErrorMessage('');
    }, 2000); // Hide error message after 2 seconds
  };

  const clearFields = () => {
    setCurrentPassword('');
    setNewPassword('');
    setConfirmPassword('');
    setErrorMessage('');
  };

  return (
    <AuthCheck >
    <div>
      <section className="shotbg">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="content-heading">
                <h2>Change Password</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="signup-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-4">
              <Sidebar />
            </div>
            <div className="col-lg-9 col-md-8">
              <div className="dashboard-view">
                <div className="tab-content">
                  <div id="tabs1" className="tab-pane active">
                    <div className="myaccounttab"> 
                      <div className="form-design">
                        <h3 className="tabtitle">Change Password</h3> 
                        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                        {successMessage && <div className="alert alert-success">{successMessage}</div>}
                        <form onSubmit={handleSubmit}>
                          <div className="form-group">
                            <label>Current Password</label>
                            <div className="passwordgroup">
                              <input
                                type={showCurrentPassword ? 'text' : 'password'}
                                className="form-control"
                                value={currentPassword}
                                onChange={(e) => setCurrentPassword(e.target.value)}
                              />
                              <a href="javascript:void(0)" onClick={toggleShowCurrentPassword}>
                                {showCurrentPassword ? <FaEyeSlash /> : <FaEye />}
                              </a>
                            </div>
                          </div>
                          <div className="form-group">
                            <label>New Password</label>
                            <div className="passwordgroup">
                              <input
                                type={showNewPassword ? 'text' : 'password'}
                                className="form-control"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                              />
                              <a href="javascript:void(0)" onClick={toggleShowNewPassword}>
                                {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                              </a>
                            </div>
                          </div>
                          <div className="form-group">
                            <label>Confirm Password</label>
                            <div className="passwordgroup">
                              <input
                                type={showConfirmPassword ? 'text' : 'password'}
                                className="form-control"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                              />
                              <a href="javascript:void(0)" onClick={toggleShowConfirmPassword}>
                                {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                              </a>
                            </div>
                          </div>
                          <div className="form-group loginpage-btn">
                            <input type="submit" value="Change Password" className="btn btn-info" />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    </AuthCheck>
  );
};

export default ChangePassword;
