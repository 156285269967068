import React from "react";
import { useNavigate, Link } from "react-router-dom";

const page_not_found = () => {
  return (
    <div className="notfoundbg">
      <div className="notfound-sec">
        <div className="notfound-in">
          <Link to="/" className="notfoundlogo">
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/logo.png`}
              className="img-fluid"
              alt=""
            />
          </Link>
          <h1>Oops !</h1>
          <h2>404 - PAGE NOT FOUND</h2>
          <p>
            The page you are looking for might have been removed had its name
            changed or is temporarily unavailable
          </p>
          <Link to="/" className="btn btn-info btndark">
            Go To Homepage
          </Link>
        </div>
      </div>
    </div>
  );
};

export default page_not_found;
