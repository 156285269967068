import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import apiFunctions from "../Settings/Api";
import { useNavigate, Link } from "react-router-dom";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    // Basic validation
    if (!email.trim() || !password.trim()) {
      setError("Please enter both email and password.");
      return;
    }
    try {
      const response = await apiFunctions.loginUser({ email, password });
      console.log(response);
      setError("");
      localStorage.setItem("user", JSON.stringify(response.user));
      localStorage.setItem("AuthToken", response.token);
      localStorage.setItem("buyplan", JSON.stringify(response.buyplan));
      if (response.buyplan != "null") {
        navigate("/user-dashboard");
        location.reload("/user-dashboard");
      } else {
        navigate("/plan");
        location.reload("/plan");
      }
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div>
      <div className="topheader">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="topheader-row">
                <div className="dashlogo">
                  <Link to="/">
                    <img
                      src="assets/images/dlogo.png"
                      className="img-fluid"
                      alt=""
                    />
                  </Link>
                </div>
                <div className="dashright">
                  <div className="dontaccount">
                    <p>
                      Don’t have an account? <Link to="/signup">Register Now</Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="webspace"></div>

      <div className="dashfooter">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3">
              <Link to="/" className="backwebsite">
                Back to Main Website{" "}
                <img
                  src="assets/images/Icontopright.svg"
                  className="img-fluid"
                  alt=""
                />
              </Link>
            </div>
            <div className="col-md-6">
              <p className="dashcopyright">
                Proudly Made In India | Capovex Research and Analytics Limited ©
                All Rights Reserved
              </p>
            </div>
            <div className="col-md-3">
              <div className="dashfooter-help">
                <a href="#" className="btn btn-info">
                  <img src="assets/images/help.svg" className="img-fluid" alt="" />
                  Get Help
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <section className="signup-sec signuppage">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="signup-left">
                <h2>Capovex Core Systems</h2>
                <p>
                  Restricted Access. If you have reached here mistakenly.
                  Leave..!!
                </p>
              </div>
            </div>
            <div className="col-md-6">
              {error && <div className="alert alert-danger">{error}</div>}
              <div className="form-design">
                <div className="form-design-heading">
                  <h2>Hello Again!</h2>
                  <h3>Welcome Back</h3>
                </div>
                <form onSubmit={handleLogin}>
                  <div className="form-group">
                    <div className="form-groupimg">
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter Email Address"
                      />
                      <i className="la la-envelope-o"></i>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-groupimg">
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Enter Password"
                      />
                      {/* <a href="javascript:void(0);" onClick={() => setShowPassword((prev) => !prev)}>
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                      </a> */}
                      {/* <i className="la la-lock"></i>
                    </div>
                  </div>
                  <div className="form-group loginpage-btn">
                    <input
                      type="submit"
                      value="Login"
                      className="btn btn-info width100"
                    />
                  </div>
                  <div className="form-group forgotpassword"> */}
                    {/* /forgot-password */}
                    {/* <Link to="#">Forgot Password</Link>
                  </div>
                </form>
                <div className="form-group dontaccount">
                  <p>
                    Don't have an account? <Link to="/signup">Sign Up</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
};

export default Login;
