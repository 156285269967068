import React, { useState, useEffect } from 'react';
import apiFunctions from '../../Settings/Api';
import { useNavigate, Link } from 'react-router-dom';

function Plan() {
  const [planList, setPlanList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [success, setSuccess] = useState(null);
  const Selectedpalan = JSON.parse(localStorage.getItem('buyplan'));
  const palanCheck = localStorage.getItem('buyplan') ?? null;
  const AuthCheck = localStorage.getItem('AuthToken') ?? null;
  
  useEffect(() => {
    const fetchPlanList = async () => {
      try {
        const response = await apiFunctions.getPlanList();
        if (response.plans && Array.isArray(response.plans)) {
          setPlanList(response.plans);
        } else {
          throw new Error('Invalid data format: Expected an array of plans.');
        }
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };
    fetchPlanList();
  }, []);

  const handleBuyNow = async (planId) => {
    try {
      const AuthToken = localStorage.getItem('AuthToken');
      const response = await apiFunctions.buyPlan(planId, AuthToken);
      localStorage.setItem('buyplan', JSON.stringify(response.buyplan));
      setSuccess('Plan purchased successfully.');
      setTimeout(() => {
        navigate('/user-dashboard');
      }, 2000);
    } catch (error) {
      setError(error);
    }
  };

  const handleCancelPlan = async () => {
    const confirmCancel = window.confirm('Are you sure you want to cancel this plan?');
    if (confirmCancel) {
      try {
        const AuthToken = localStorage.getItem('AuthToken');
        const response = await apiFunctions.CancelPlan(AuthToken);
        if (response.status === 200) {
          localStorage.setItem('buyplan', 'false');
          navigate('/');
        } else {
          console.error('Cancellation failed:', response.data.message);
        }
      } catch (error) {
        console.error('Cancellation error:', error.message);
      }
    }
  };

  const handleSendOnSignup = (planId, planPrice) => {
    navigate('/signup', { state: { planId, planPrice } });
  }; 

  if (loading) return <div className='text-center'>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div>
      <section className="shotbg">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="content-heading">
                <h2>Subscription</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      {success && <div className='text-center alert alert-success' role="alert">{success}</div>}
      <section className="signup-sec">
        <div className="container">
          <div className="row">
            {Selectedpalan && Selectedpalan !== 'false' ? (
              <div className="col-lg-5 col-md-6">
                <div className={`plan-card ${Selectedpalan.planid === 2 ? 'batter' : (Selectedpalan.planid === 3 ? 'best' : '')}`}>
                  <div className="iconbox"><div className="icon"><i className={`fa ${Selectedpalan.planid === 2 ? 'fa-diamond' : (Selectedpalan.planid === 3 ? 'fa-rocket' : 'fa-paper-plane')}`}></i></div></div>
                  <div className="plandesc">
                    <h3>{Selectedpalan.name}</h3>
                    <h6>${Selectedpalan.price}<span>/ mo</span></h6>
                    <ul>
                      <li>{Selectedpalan.description}</li>
                    </ul>
                    <div className='plancancelupgrade'>
                      <Link to='/upgrade-plan'  className="btn btn-info">Upgrade Now</Link>
                      <button type='button' onClick={handleCancelPlan} className="btn btn-info bg-danger">Cancel</button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              planList.map((plan) => (
                <div key={plan.id} className="col-md-4">
                  <div className={`plan-card ${plan.id === 2 ? 'batter' : (plan.id === 3 ? 'best' : '')}`}>
                    <div className="iconbox"><div className="icon"><i className={`fa ${plan.id === 2 ? 'fa-diamond' : (plan.id === 3 ? 'fa-rocket' : 'fa-paper-plane')}`}></i></div></div>
                    <div className="plandesc">
                      <h3>{plan.name}</h3>
                      <h6>${plan.price}<span>/ mo</span></h6>
                      <ul>
                        <li>{plan.description}</li>
                      </ul>
                      {AuthCheck === null ? (
                        <button type='button' onClick={() => handleSendOnSignup(plan.id, plan.price)} className="btn btn-info">Buy Now</button>
                      ) : (
                        AuthCheck !== null && palanCheck === 'false' ? (
                          <button type='button' onClick={() => handleBuyNow(plan.id)} className="btn btn-info">Buy Now</button>
                        ) : null
                      )}
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Plan;
