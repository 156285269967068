import React, { useEffect } from 'react';
import { useNavigate, Link } from "react-router-dom";

const pricing = () => {

    useEffect(() => {
        const initializeCarousel = () => {
          const owlCarousel1 = $('#owl-demo1');
          const owlCarousel2 = $('#owl-demo2');
      
          if (owlCarousel1.length) {
            owlCarousel1.owlCarousel({
              center: false,
              items: 2,
              loop: true,
              margin: 20,
              responsive: {
                500: { items: 1 },
                900: { items: 1 },
                1200: { items: 2 }
              }
            });
          }
      
          if (owlCarousel2.length) {
            owlCarousel2.owlCarousel({
              center: false,
              items: 1,
              loop: true,
              margin: 20,
              responsive: {
                500: { items: 1 },
                900: { items: 1 },
                1200: { items: 1 }
              }
            });
          }
      
          $(".owl-prev").html('<i class="fa fa-angle-left" aria-hidden="true"></i>');
          $(".owl-next").html('<i class="fa fa-angle-right" aria-hidden="true"></i>');
        };
      
        $(document).ready(() => {
          initializeCarousel();
        });
      
        // Clean up function
        return () => {
          const owlCarousel1 = $('#owl-demo1');
          const owlCarousel2 = $('#owl-demo2');
      
          if (owlCarousel1.length) {
            owlCarousel1.trigger('destroy.owl.carousel');
          }
      
          if (owlCarousel2.length) {
            owlCarousel2.trigger('destroy.owl.carousel');
          }
        };
      }, []);
      

  return (
    <div>
      <section class="pricingsec">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="headingsec">
                <h2>Navigate Your Financial Future with Precision</h2>
                <h4>
                  Customized Pricing Structures to Suit Your Capital
                  Requirements
                </h4>
                <p>
                  Whether you are a novice investor or an experienced market
                  participant, our meticulously designed pricing plans are
                  crafted to align with your financial objectives and expertise.
                  Discover the ideal plan that complements your trading approach
                  and budge
                </p>
              </div>
            </div>
          </div>

          <div class="row pricingrow">
            <div class="col-md-4">
              <div class="pricing-card">
                <div class="pricing-cardin">
                  <h3>Free</h3>
                  <p>
                    Still wondering if this is a right tool for you. Why not
                    Give it a try..!!
                  </p>
                  <h2>
                    <sup>₹</sup>0
                  </h2>
                  <h6>Per month</h6>
                  <a href="#" class="btn btn-info">
                    Register Now
                  </a>
                  <ul>
                    <li>All Essential tools & features</li>
                    <li>14 days of limited screener usage</li>
                    <li>Read up to 5 premium articles.</li>
                    <li>Search and analyze up to 10 stocks</li>
                    <li>Daily after-market summary reports</li>
                    <li>Stay updated with weekly insights</li>
                    <li>Basic portfolio evaluations</li>
                    <li>Access to Knowledge Center</li>
                    <li>Access to Recorded Podcasts</li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="pricing-card mostpopular">
                <h5>Most Popular</h5>
                <div class="pricing-cardin">
                  <h3>Premium</h3>
                  <p>
                    Unlock the Premium Access to Drishti, Screener & Analytics
                  </p>
                  <h2>
                    <sup>₹</sup>750
                  </h2>
                  <h6>Per month</h6>
                  <a href="#" class="btn btn-info">
                    Buy Now
                  </a>
                  <ul>
                    <li>All Essential tools & features</li>
                    <li>14 days of limited screener usage</li>
                    <li>Read up to 5 premium articles.</li>
                    <li>Search and analyze up to 10 stocks</li>
                    <li>Daily after-market summary reports</li>
                    <li>Stay updated with weekly insights</li>
                    <li>Basic portfolio evaluations</li>
                    <li>Access to Knowledge Center</li>
                    <li>Access to Recorded Podcasts</li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="pricing-card">
                <div class="pricing-cardin">
                  <h3>Pro</h3>
                  <p>
                    Go advance from Charting to Analytics tools & Unlimited
                    Access{" "}
                  </p>
                  <h2>
                    <sup>₹</sup>2,000
                  </h2>
                  <h6>Per month</h6>
                  <a href="#" class="btn btn-info">
                    Buy Now
                  </a>
                  <ul>
                    <li>All Essential tools & features</li>
                    <li>14 days of limited screener usage</li>
                    <li>Read up to 5 premium articles.</li>
                    <li>Search and analyze up to 10 stocks</li>
                    <li>Daily after-market summary reports</li>
                    <li>Stay updated with weekly insights</li>
                    <li>Basic portfolio evaluations</li>
                    <li>Access to Knowledge Center</li>
                    <li>Access to Recorded Podcasts</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="pricing-faq">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="headingsec">
                <h2>Frequently Asked Queries</h2>
              </div>
            </div>
          </div>
        </div>
        <div class="pricing-faqbg">
          <div class="container">
            <div class="row">
              <div class="col-md-6">
                <div class="pricing-faqcard">
                  <h5>What are payment options for Premium plans?</h5>
                  <p>
                    We accept all major credit cards, Debit Cards, and digital
                    wallets for payment along with UPI, Netbanking and Pay Later
                    Services. We Accept Global Payments for international users.
                  </p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="pricing-faqcard">
                  <h5>What features are included in the Free Plan?</h5>
                  <p>
                    The Free Plan offers basic access to our tools, including
                    stock search and analysis for up to 7 stocks, limited
                    screener access, weekly reports, and a limited watchlist.
                  </p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="pricing-faqcard">
                  <h5>
                    What features does the Pro Plan offer for advanced traders
                  </h5>
                  <p>
                    The Pro Plan offers advanced screeners, full portfolio
                    management, direct trade execution, and personalized
                    support, making it ideal for advanced traders looking for
                    comprehensive tools and services
                  </p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="pricing-faqcard">
                  <h5>
                    Can I upgrade from Free to Premium or Pro at any time?
                  </h5>
                  <p>
                    Yes, you can upgrade your plan at any time to access more
                    features and tools.
                  </p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="pricing-faqcard">
                  <h5>
                    What does the portfolio analysis include in the Premium
                    Plan?
                  </h5>
                  <p>
                    It includes the ability to add, analyze, and get a
                    comprehensive view of your Demat portfolio within our
                    platform.
                  </p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="pricing-faqcard">
                  <h5>
                    Can I cancel my Premium or Pro subscription at any time?
                  </h5>
                  <p>
                    Yes, you can cancel your subscription at any time. Access
                    will continue until the end of the billing period.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="pricingclient-sec">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="headingsec">
                <h2>What our clients have to say</h2>
                <p>
                  Reviews from our Extentive Beta users who are not only
                  individuals but esteem finance professionals & Industry
                  Experts
                </p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div id="demos" class="pricingclientcarousel">
                <div id="owl-demo2" class="owl-carousel owl-theme">
                  <div class="item">
                    <div class="pricingclient-item">
                      <div class="pricingclient-desc">
                        <div class="pricingclient-img">
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/team.jpg`}
                            class="img-fluid"
                            alt=""
                          />
                        </div>
                        <ul>
                          <li>
                            <i class="fa fa-star" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i class="fa fa-star" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i class="fa fa-star" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i class="fa fa-star" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i class="fa fa-star" aria-hidden="true"></i>
                          </li>
                        </ul>
                        <h3>"Morning this easy, was need of an hour"</h3>
                        <p>
                          Before discovering this platform, my mornings were a
                          constant battle. To be at the top of my game during
                          the early market hours, which are crucial but were
                          also consuming my personal life. The process was not
                          only tiresome but incredibly time-consuming. Thanks to
                          this amazing platform, I've transformed the way I
                          interact with the market. Now, I can get comprehensive
                          analyses and crucial market insights without being
                          glued to my screen.
                        </p>
                        <div class="pricingclient-name">
                          <h4>
                            Rajat Vikas Sharma{" "}
                            <span>Regional head at IndusIND Bank</span>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="item">
                    <div class="pricingclient-item">
                      <div class="pricingclient-desc">
                        <div class="pricingclient-img">
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/team.jpg`}
                            class="img-fluid"
                            alt=""
                          />
                        </div>
                        <ul>
                          <li>
                            <i class="fa fa-star" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i class="fa fa-star" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i class="fa fa-star" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i class="fa fa-star" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i class="fa fa-star" aria-hidden="true"></i>
                          </li>
                        </ul>
                        <h3>"Morning this easy, was need of an hour"</h3>
                        <p>
                          Before discovering this platform, my mornings were a
                          constant battle. To be at the top of my game during
                          the early market hours, which are crucial but were
                          also consuming my personal life. The process was not
                          only tiresome but incredibly time-consuming. Thanks to
                          this amazing platform, I've transformed the way I
                          interact with the market. Now, I can get comprehensive
                          analyses and crucial market insights without being
                          glued to my screen.
                        </p>
                        <div class="pricingclient-name">
                          <h4>
                            Rajat Vikas Sharma{" "}
                            <span>Regional head at IndusIND Bank</span>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default pricing;
