// src/Settings/AppRoutes.js
import React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Header from '../components/includes/header';
import Footer from '../components/includes/Footer';
import Home from '../components/Home';
import SignUp from '../Auth/SignUp';
import Login from '../Auth/Login';
import ForgotPassword from '../Auth/ForgotPassword';
import Plan from '../components/Plan/Index';
import Upgrade from '../components/Plan/Upgrade';
import Dashboard from '../components/Dashboard/Index';
import UserAccount from '../components/Dashboard/UserAccount';
import UserTransactions from '../components/Dashboard/UserTransactions';
import OrderManagement from '../components/Dashboard/OrderManagement';
import ChangePassword from '../components/Dashboard/ChangePassword';
import DynamicPolicy from '../pages/policy/DynamicPolicy';
import ContentInternal from '../pages/content_internal/index';
import ContentDetail from '../pages/content_internal/ContentDetail';
import ContentPreview from '../pages/content_internal/content_preview';
import PageNotFound from '../pages/page_not_found';
import ScamAlert from '../pages/scam_alert';
import HelpDisk from '../pages/helpdisk';
import Connect from '../pages/connect';
import OurTeam from '../pages/our_team';
import Vision from '../pages/vision';
import FAQ from '../pages/faq';
import ContactUs from '../pages/contact_us';
import About from '../pages/about';
import Feature from '../pages/features';
import Pricing from '../pages/pricing';

const AppRoutes = () => {
  const location = useLocation();
  const isAuthRoute = location.pathname === '/signup' || location.pathname === '/login' || location.pathname === '/forgot-password' || location.pathname === '/page-not-found';
  
  return (
    <>
      {!isAuthRoute && <Header />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/upgrade-plan" element={<Upgrade />} />
        <Route path="/plan" element={<Plan />} />
        <Route path="/user-dashboard" element={<Dashboard />} />
        <Route path="/user-account" element={<UserAccount />} />
        <Route path="/user-transactions" element={<UserTransactions />} />
        <Route path="/user-order-management" element={<OrderManagement />} />
        <Route path="/user-change-password" element={<ChangePassword />} />

        {/* New Routes */}
        <Route path="/login" element={<Login />} />
        <Route path="/vision" element={<Vision />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/helpdisk" element={<HelpDisk />} />
        <Route path="/our-team" element={<OurTeam />} />
        <Route path="/scam-alert" element={<ScamAlert />} />
        <Route path="/connect" element={<Connect />} />
        <Route path="/content-internal" element={<ContentInternal />} />
        <Route path="/content-details/:id" element={<ContentDetail />} />
        <Route path="/content-preview" element={<ContentPreview />} />

        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/about" element={<About />} />
        <Route path="/features" element={<Feature />} />
        <Route path="/pricing" element={<Pricing />} />
        

        {/* Dynamic Route policy */}
        <Route path="/policy/:prefix" element={<DynamicPolicy />} />

        {/*Routes Not Found*/}
        <Route path="/page-not-found" element={<PageNotFound />} />
        {/* <Route path="*" element={<Navigate to="/page-not-found" />} /> */}
      </Routes>
      {!isAuthRoute && <Footer />}
    </>
  );
};

export default AppRoutes;
