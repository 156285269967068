import React, { useState, useEffect } from 'react';
import apiFunctions from '../../Settings/Api';
import { Link } from "react-router-dom";

const ContentInternal = () => {
  const [contentList, setContentList] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    fetchContentList();
  }, [currentPage, searchQuery]); // Fetch content when currentPage or searchQuery changes

  const fetchContentList = async () => {
    setLoading(true);
    try {
      const response = await apiFunctions.getContentinternalList({ page: currentPage, search: searchQuery });
      if (currentPage === 1) {
        setContentList(response.data.data.data); // Set new content if it's the first page
      } else {
        setContentList(prevList => [...prevList, ...response.data.data.data]); // Append new data to existing contentList
      }
      setHasMore(response.data.data.next_page_url !== null); // Update hasMore based on pagination link availability
    } catch (error) {
      console.error('Error fetching content list:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleLoadMore = () => {
    if (!loading && hasMore) {
      setCurrentPage(prevPage => prevPage + 1); // Increment page number
    }
  };

  const truncateContent = (content, wordsCount) => {
    const div = document.createElement('div');
    div.innerHTML = content;
    const text = div.textContent || div.innerText || '';
    const words = text.split(' ');
    const truncatedContent = words.slice(0, wordsCount).join(' ');
    return words.length > wordsCount ? `${truncatedContent} ...` : truncatedContent;
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to the first page on search
  };

  const filteredContentList = contentList.filter((content) => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    return (
      content.title?.toLowerCase().includes(lowerCaseQuery) ||
      content.description?.toLowerCase().includes(lowerCaseQuery) ||
      `${content.first_name} ${content.last_name}`.toLowerCase().includes(lowerCaseQuery) // Combine first_name and last_name for search
    );
  });

  return (
    <div>
      <section className="help-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="content-heading capitalizetext">
                <h2>Content List</h2>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                  <button className="btn btn-success" type="submit">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/search.png`}
                      className="img-fluid"
                      alt=""
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container mb-5">
          <div className="row p-3">
            <div className="col-sm-1"><h6>Status</h6></div>
            <div className="col-sm-3"><h6>Title</h6></div>
            <div className="col-sm-4"><h6>Content</h6></div>
            <div className="col-sm-3"><h6>Author</h6></div>
            <div className="col-sm-1"></div>
          </div>
          {filteredContentList.length > 0 ? (
            filteredContentList.map((content) => (
              <div className="row card d-flex flex-row mb-3 p-3" key={content.id}>
                <div className="col-sm-1">
                  <span className={content.status === '2' ? 'text-success' : 'text-muted'}>
                    <i className="font-sm fa fa-circle"></i> live
                  </span>
                </div>
                <div className="col-sm-3">{content.title}</div>
                <div className="col-sm-4">
                  <div>{truncateContent(content.content, 5)}</div>
                </div>
                <div className="col-sm-3">{content.first_name} {content.last_name}</div>
                <div className="col-sm-1">
                  <Link to={`/content-details/${content.id}`} className="btn btn-link">
                    More
                  </Link>
                </div>
              </div>
            ))
          ) : (
            <div className="row">
              <div className="col-12 text-center">
                <p>No content available</p>
              </div>
            </div>
          )}
          {loading && (
            <div className="row">
              <div className="col-12 text-center">
                <p>Loading...</p>
              </div>
            </div>
          )}
          {!loading && hasMore && (
            <div className="row">
              <div className="col-12 text-center">
                <button className="btn btn-link" onClick={handleLoadMore}>
                  Load More
                </button>
              </div>
            </div>
          )}
          {!loading && !hasMore && filteredContentList.length === 0 && (
            <div className="row">
              <div className="col-12 text-center">
                <p>No more content to load</p>
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default ContentInternal;
