import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Spinner } from 'react-bootstrap';
import apiFunctions from '../../Settings/Api';
import Sidebar from '../Dashboard/Sidebar';
import AuthCheck from '../../Auth/AuthCheck';

const UserTransactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTransactions = async () => {
      const AuthToken = localStorage.getItem('AuthToken');
      const userData = localStorage.getItem('user');
      const user = JSON.parse(userData);
      try {
        const response = await apiFunctions.getUserTransactionsList(AuthToken, user.id);
        setTransactions(response.transaction);
      } catch (error) {
        console.error('Error fetching user transactions:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const formatPaymentStatus = (cell, row) => {
    const statusClass = cell === 'Faild' ? 'pending' : cell === 'Paid' ? 'successful' : '';
    return (
      <span className={`${statusClass}`}>{cell}</span>
    );
  };

  const paymentStatusClasses = (cell, row, rowIndex, colIndex) => {
    return cell === 'Faild' ? 'paymentstatus' : cell === 'Paid' ? 'paymentstatus' : '';
  };

  const columns = [
    { dataField: 'name', text: 'Plan Name' },
    { dataField: 'payment_id', text: 'Payment ID' },
    { 
      dataField: 'payment_status', 
      text: 'Payment Status', 
      formatter: formatPaymentStatus, // Use the formatPaymentStatus function for formatting
      classes: paymentStatusClasses // Add the cell classes for the payment status column
    },
    { dataField: 'amount', text: 'Amount', formatter: (cell, row, rowIndex, colIndex) => `$ ${cell}` },
    { dataField: 'date', text: 'Date', formatter: (cell, row, rowIndex, colIndex) => formatDate(cell) },
  ];

  return (
    <AuthCheck>
      <div>
        <section className="shotbg">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="content-heading">
                  <h2>Transactions</h2>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="signup-sec">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-4">
                <Sidebar />
              </div>
              <div className="col-lg-9 col-md-8">
                <div className="dashboard-view">
                  <div className="tab-content">
                    <div id="tabs1" className="tab-pane active">
                      <div className="tab-transactions">
                        <h3 className="tabtitle">Transactions</h3>
                        <div className="tabledesign">
                          {loading ? (
                            <div className="text-center">
                              <Spinner animation="border" role="status" />
                              <div>Loading...</div>
                            </div>
                          ) : (
                            <div className="table-responsive">
                              <BootstrapTable
                                bootstrap4
                                keyField="payment_id" // Ensure payment_id is unique for each row
                                data={transactions}
                                columns={columns}
                                pagination={paginationFactory()}
                                striped
                                hover
                                condensed
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </AuthCheck>
  );
};

export default UserTransactions;
