import React, { useEffect } from 'react';

function Home() {
 
  useEffect(() => {
    const initializeCarousel = () => {
      const owlCarousel = $('#owl-demo1');
      if (owlCarousel.length) {
        owlCarousel.owlCarousel({
          loop: true,
          margin: 20,
          items: 2,
          center: false,
          responsiveClass: true,
          nav: true,
          navText: [
            '<i class="fa fa-angle-left" aria-hidden="true"></i>',
            '<i class="fa fa-angle-right" aria-hidden="true"></i>'
          ],
          dots: false,
          autoplay: true,
          responsive: {
            500: { items: 1 },
            900: { items: 1 },
            1200: { items: 2 }
          }
        });
      }
    };

    $(document).ready(() => {
      initializeCarousel();
    });

    // Clean up function
    return () => {
      const owlCarousel = $('#owl-demo1');
      if (owlCarousel.length) {
        owlCarousel.trigger('destroy.owl.carousel');
      }
    };
  }, []);
  


  return (
    <>
      <section
        className="herobanner"
        style={{ background: "url('assets/images/herobg.jpg')" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div className="herobanner-text">
                <h1>Empowering Your Financial Future</h1>
                <p>
                  Innovative insights and tools to elevate your investment
                  strategy. Powered by AI, crafted for you.
                </p>
                <a href="#" className="btn btnbanner">
                  Know More
                </a>
              </div>
            </div>
          </div>
        </div>
        <img src={`${process.env.PUBLIC_URL}/assets/images/heroimg.png`} className="img-fluid" alt="" />
      </section>
      <section className="home-investment">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="home-investment-left">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/investment1.png`}
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="home-investment-right">
                <h2>Your AI-Powered Investment Dashboard</h2>
                <p>
                  Advanced stock research and analysis made simple and
                  accessible for every investor
                </p>
                <ul>
                  <li>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/check.svg`}
                        className="img-fluid"
                        alt=""
                      />
                    </span>
                    AI-driven research & analysis to enhance investments
                  </li>
                  <li>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/check.svg`}
                        className="img-fluid"
                        alt=""
                      />
                    </span>
                    Seamlessly connect existing Demat for analysis
                  </li>
                  <li>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/check.svg`}
                        className="img-fluid"
                        alt=""
                      />
                    </span>
                    Advanced analysis of Financial data in clear, simple terms
                  </li>
                  <li>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/check.svg`}
                        className="img-fluid"
                        alt=""
                      />
                    </span>
                    Leverage generative AI for comprehensive insights
                  </li>
                </ul>
                <a href="#" className="btn btn-info">
                  Learn more
                </a>
                <a href="#" className="btn btn-info btndark">
                  Get started
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/arrowrigth.svg`}
                    className="img-fluid"
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 order-md-2">
              <div className="home-investment-left">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/investment2.png`}
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-6 order-md-1">
              <div className="home-investment-right">
                <h2>Elevate Investments with Drishti</h2>
                <p>
                  Comprehensive market insights, Curated articles, Stock
                  Screener and reports available in five different languages
                </p>
                <ul>
                  <li>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/check.svg`}
                        className="img-fluid"
                        alt=""
                      />
                    </span>
                    Access pre-market research and post-market analysis{" "}
                  </li>
                  <li>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/check.svg`}
                        className="img-fluid"
                        alt=""
                      />
                    </span>
                    Detailed stock evaluations and exclusive premium
                  </li>
                  <li>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/check.svg`}
                        className="img-fluid"
                        alt=""
                      />
                    </span>
                    Stay updated with engaging podcasts and market news
                  </li>
                  <li>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/check.svg`}
                        className="img-fluid"
                        alt=""
                      />
                    </span>
                    AI Research and Screeners to optimize your investment
                    portfolio
                  </li>
                </ul>
                <a href="#" className="btn btn-info">
                  Learn more
                </a>
                <a href="#" className="btn btn-info btndark">
                  Get started
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/arrowrigth.svg`}
                    className="img-fluid"
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="registrations-home">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="registrations-left">
                <h2>Registrations Open for MANTHAN </h2>
                <p>
                  Register your interest for early access of India’s most
                  advanced Securities Dashboard.
                </p>
                <a href="#" className="btn btn-info">
                  Register Now
                </a>
              </div>
            </div>
            <div className="col-md-6">
              <div className="registrations-left registrations2">
                <h2>Drishti, Now in Five Languages</h2>
                <p>
                  Subscribe to Drishti for comprehensive investment insights,
                  now available in five different languages.
                </p>
                <a href="#" className="btn btn-info">
                  Subscribe Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="counters-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="headingsec">
                <h2>Few Counters at a Glance</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="counters-card">
                <h3>8+</h3>
                <h4>Advanced Indicators</h4>
                <p>
                  Our platform features over eight meticulously coded
                  indicators, providing deep insights to enhance your trading
                  strategies
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="counters-card">
                <h3>94%</h3>
                <h4>Client satisfaction</h4>
                <p>
                  We take pride in our high customer satisfaction rate,
                  reflecting our commitment to delivering top-notch services.
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="counters-card">
                <h3>250+</h3>
                <h4>Curated Reports</h4>
                <p>
                  Access a vast library of over 200 expertly curated reports,
                  designed to keep you informed about the latest market trends
                  and insights
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="counters-card">
                <h3>100+</h3>
                <h4>Beta Testers</h4>
                <p>
                  Join over 100 beta testers who are already benefiting from our
                  cutting-edge market analysis tools while helping us make it
                  better.
                </p>
              </div>
            </div>
          </div>

          <div className="plansec">
            <div className="row">
              <div className="col-md-12">
                <div className="headingsec">
                  <h2>A Smart way to plan Investments</h2>
                  <p>
                    Analyze global market trends and segments to see how they
                    impact your portfolio, helping you make informed investment
                    decisions
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="plansec-card">
                  <span>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/plan1.svg`}
                      className="img-fluid"
                      alt=""
                    />
                  </span>
                  <h3>Global Insights, Local Expertise</h3>
                  <p>
                    Capovex sources insights from over 20 leading platforms
                    worldwide, verifies their accuracy, and delivers carefully
                    curated content just for you.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="plansec-card">
                  <span>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/plan2.svg`}
                      className="img-fluid"
                      alt=""
                    />
                  </span>
                  <h3>Global Market Pulse</h3>
                  <p>
                    Access advanced analysis of global events and multiple
                    market segments, helping you understand their impact on your
                    portfolio.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="plansec-card">
                  <span>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/plan3.svg`}
                      className="img-fluid"
                      alt=""
                    />
                  </span>
                  <h3>Customized Alerts</h3>
                  <p>
                    Receive personalized alerts tailored to your investment
                    interests and portfolio, ensuring you never miss any
                    critical update.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="plansec-card">
                  <span>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/plan4.svg`}
                      className="img-fluid"
                      alt=""
                    />
                  </span>
                  <h3>Simplified Technical Mastery</h3>
                  <p>
                    Enhance your technical analysis and understanding with our
                    easy-to-use tools, empowering you to make well-informed
                    decisions
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="plansec-card">
                  <span>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/plan5.svg`}
                      className="img-fluid"
                      alt=""
                    />
                  </span>
                  <h3>Beyond Fundamentals</h3>
                  <p>
                    Dive into a powerful dashboard offering in-depth data
                    analysis that goes beyond mere fundamental metrics,
                    providing a complete financial picture
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="plansec-card">
                  <span>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/plan6.svg`}
                      className="img-fluid"
                      alt=""
                    />
                  </span>
                  <h3>Seamless Integration</h3>
                  <p>
                    Integrate your existing tools and platforms seamlessly with
                    Capovex, making it easier to manage all your financial
                    activities in one place
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="insights-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="insights-left">
                <div className="headingsec">
                  <h2>Stay Updated with Capovex Insights</h2>
                  <p>
                    Join our newsletter to receive free articles, exclusive
                    market analysis, and sneak peeks into our premium content.
                    Stay informed and ahead of the market trends with Capovex.
                  </p>
                </div>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your email address"
                  />
                  <button className="btn btn-success" type="submit">
                    Subscribe
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-6"></div>
          </div>
        </div>
      </section>

      <section className="client-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="headingsec">
                <h2>What our Users have to say</h2>
                <p>
                  Users say it all. Whether you are a seasoned investor or a
                  novice, Capovex is built for all.
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div id="demos" className="clientcarousel">
                <div id="owl-demo1" className="owl-carousel owl-theme">
                  <div className="item">
                    <div className="clientsecowl-item">
                      <div className="clientsecowl-desc">
                        <div className="item-img">
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/avatar.svg`}
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <h3>"Revitalized my work approach"</h3>
                        <p>
                          Lorem ipsum dolor sit amet consectetur eget maecenas
                          sapien fusce egestas risus purus suspendisse turpis
                          volutpat onare imperdiet bibendum eleifend quam.
                        </p>
                        <div className="item-desc">
                          <h4>Brian Clark</h4>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="item">
                    <div className="clientsecowl-item">
                      <div className="clientsecowl-desc">
                        <div className="item-img">
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/avatar.svg`}
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <h3>"Transformed my work process"</h3>
                        <p>
                          Lorem ipsum dolor sit amet consectetur eget maecenas
                          sapien fusce egestas risus purus suspendisse turpis
                          volutpat onare imperdiet bibendum.
                        </p>
                        <div className="item-desc">
                          <h4>Stephanie Powell</h4>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="item">
                    <div className="clientsecowl-item">
                      <div className="clientsecowl-desc">
                        <div className="item-img">
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/avatar.svg`}
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <h3>"Revitalized my work approach"</h3>
                        <p>
                          Lorem ipsum dolor sit amet consectetur eget maecenas
                          sapien fusce egestas risus purus suspendisse turpis
                          volutpat onare imperdiet bibendum eleifend quam.
                        </p>
                        <div className="item-desc">
                          <h4>Brian Clark</h4>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="item">
                    <div className="clientsecowl-item">
                      <div className="clientsecowl-desc">
                        <div className="item-img">
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/avatar.svg`}
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <h3>"Transformed my work process"</h3>
                        <p>
                          Lorem ipsum dolor sit amet consectetur eget maecenas
                          sapien fusce egestas risus purus suspendisse turpis
                          volutpat onare imperdiet bibendum.
                        </p>
                        <div className="item-desc">
                          <h4>Stephanie Powell</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;
