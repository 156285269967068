import React from "react";

const features = () => {
  return (
    <div>
      <section className="featuresbg">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div className="featuresbg-text">
                <h2>
                  India’s Most Powerful Research & Analysis Tool for Investing
                </h2>
                <p>
                  One of the world’s most powerful tool for investment,
                  research, community and risk management solutions
                </p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="featuresbg-img">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/featuremain.png`}
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="multilingual-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="headingsec">
                <h2>Multilingual Financial Intelligence</h2>
                <p>
                  Unlock a World of Powerful Insights, curated analysis and
                  tools , All in Your Preferred Language
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <ul>
                <li>
                  <span>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/multilingualimg1.png`}
                      className="img-fluid"
                      alt=""
                    />
                  </span>
                </li>
                <li>
                  <span>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/multilingualimg2.png`}
                      className="img-fluid"
                      alt=""
                    />
                  </span>
                </li>
                <li>
                  <span>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/multilingualimg3.png`}
                      className="img-fluid"
                      alt=""
                    />
                  </span>
                </li>
                <li>
                  <span>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/multilingualimg4.png`}
                      className="img-fluid"
                      alt=""
                    />
                  </span>
                </li>
                <li>
                  <span>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/multilingualimg5.png`}
                      className="img-fluid"
                      alt=""
                    />
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="home-investment features-grow">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="home-investment-left">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/grow.png`}
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="home-investment-right">
                <h2>Discover the key to grow the Capital with Manthan</h2>
                <p>
                  Artifical Intellengence taken a step ahead with bionic
                  approach. Your capital investments take a leap with technology
                  advancement.
                </p>
                <ul>
                  <li>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/check.svg`}
                        className="img-fluid"
                        alt=""
                      />
                    </span>
                    Experience market intelligence redefined. Our platform
                    delivers comprehensive insights across all asset classes,
                    aggregating and analyzing global market information to
                    provide unmatched clarity and depth in financial analysis.
                  </li>
                  <li>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/check.svg`}
                        className="img-fluid"
                        alt=""
                      />
                    </span>
                    Revolutionize your data interaction with our advanced
                    analytical tools. Customize viewing panels, set
                    sophisticated alerts, and create dynamic charts tailored to
                    real-time market conditions, enhancing your decision-making
                    speed and precision.
                  </li>
                  <li>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/check.svg`}
                        className="img-fluid"
                        alt=""
                      />
                    </span>
                    Explore market dynamics through advanced econometrics and
                    regionometrics analysis. These tools offer deep insights
                    into economic trends and regional growth patterns, refining
                    your strategy and improving market forecasts.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="levelup-sec"
        style={{ background: "url('assets/images/levelupbg.jpg')" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="levelup-text">
                <h2>Want to level Up your Trading Game?</h2>
                <a href="#" className="btn btn-info">
                  Subscribe Now{" "}
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/arrowrigth.png`}
                    className="img-fluid"
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="home-investment features-finance">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="home-investment-right">
                <h2>Complexities in Finance is a thing of the Past</h2>
                <p>
                  With the development of quantum computing and artificial
                  intelligence we are simplifying the financial analysis for
                  retail users
                </p>
                <ul>
                  <li>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/check.svg`}
                        className="img-fluid"
                        alt=""
                      />
                    </span>
                    Link with financial professionals globally via a robust
                    communication network designed for seamless collaboration
                    and information exchange. Our platform ensures you're always
                    connected to the financial community's collective expertise.
                  </li>
                  <li>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/check.svg`}
                        className="img-fluid"
                        alt=""
                      />
                    </span>
                    Stay connected to the market with our high-performance
                    mobile app. Access crucial market tools and information
                    anytime, anywhere, ensuring you're always in touch, whether
                    in transit or at meetings.
                  </li>
                  <li>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/check.svg`}
                        className="img-fluid"
                        alt=""
                      />
                    </span>
                    Protect your investments with our integrated risk management
                    and compliance features. Our platform offers thorough risk
                    assessments, compliance tracking, and proactive alerts to
                    effectively navigate and mitigate complex regulatory
                    challenges.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="home-investment-left">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/finance.png`}
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="home-investment features-grow robustbg">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="home-investment-left">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/robust.png`}
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="home-investment-right">
                <h2>A Robust System Built on Neuron Architecture</h2>
                <p>
                  Harness the power of cutting-edge technology with our
                  platform’s neuron architecture, an advanced framework for
                  Quantum Processing. This powerful combination enables our
                  system to
                </p>
                <ul>
                  <li>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/check.svg`}
                        className="img-fluid"
                        alt=""
                      />
                    </span>
                    Adaptive Learning: Utilize algorithms that adapt and evolve,
                    improving their accuracy and functionality with each use.
                  </li>
                  <li>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/check.svg`}
                        className="img-fluid"
                        alt=""
                      />
                    </span>
                    Unique Capabilities: Analyze vast amount of unstructured
                    data by extracting insights from sources at an unprecedented
                    scale.
                  </li>
                  <li>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/check.svg`}
                        className="img-fluid"
                        alt=""
                      />
                    </span>
                    Quantum-Powered Speed: Leverage quantum processing to
                    perform complex calculations at incredible speeds.
                  </li>
                  <li>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/check.svg`}
                        className="img-fluid"
                        alt=""
                      />
                    </span>
                    Intelligent Automation: Employ AI-driven automation to
                    streamline operations, enhance decision-making processes.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="grow-business">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="headingsec">
                <h2>Discover the key to grow your business</h2>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipiscing eli mattis
                  sit phasellus mollis sit aliquam sit nullam neque ultrices.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="home-investment features-finance powersec">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="home-investment-right">
                <h2>Power of a Powerful tool Now at Your Hands</h2>
                <p>
                  Embrace unmatched versatility with our platform, designed to
                  deliver responsiveness and multi-device capability. Seamlessly
                  transition between devices without sacrificing functionality
                </p>
                <ul>
                  <li>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/check.svg`}
                        className="img-fluid"
                        alt=""
                      />
                    </span>
                    Optimized Responsiveness: Enjoy a seamless experience across
                    all devices, with each interface designed for peak
                    performance
                  </li>
                  <li>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/check.svg`}
                        className="img-fluid"
                        alt=""
                      />
                    </span>
                    Universal Compatibility: Access your financial tools on
                    various devices including Vision Pro, Echo devices, and
                    smartwatches
                  </li>
                  <li>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/check.svg`}
                        className="img-fluid"
                        alt=""
                      />
                    </span>
                    Inclusive Design: Catering to all levels of expertise, from
                    novices to seasoned traders, ensuring usability and
                    comprehensive functionality.
                  </li>
                  <li>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/check.svg`}
                        className="img-fluid"
                        alt=""
                      />
                    </span>
                    Reliable Cloud Infrastructure: Benefit from consistent,
                    cloud-based 100% uptime, allowing you to access your
                    financial resources from anywhere in the world, at any time.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="home-investment-left">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/power.png`}
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="analysts-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div className="analysts-text">
                <h2>Trusted by top-tier Analysts worldwide</h2>
              </div>
            </div>
            <div className="col-md-5">
              <ul className="analysts-img">
                <li>
                  <span>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/analysts1.svg`}
                      className="img-fluid"
                      alt=""
                    />
                  </span>
                </li>
                <li>
                  <span>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/analysts2.svg`}
                      className="img-fluid"
                      alt=""
                    />
                  </span>
                </li>
                <li>
                  <span>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/analysts3.svg`}
                      className="img-fluid"
                      alt=""
                    />
                  </span>
                </li>
                <li>
                  <span>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/analysts4.svg`}
                      className="img-fluid"
                      alt=""
                    />
                  </span>
                </li>
                <li>
                  <span>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/analysts5.svg`}
                      className="img-fluid"
                      alt=""
                    />
                  </span>
                </li>
                <li>
                  <span>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/analysts6.svg`}
                      className="img-fluid"
                      alt=""
                    />
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default features;
