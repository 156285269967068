import React from "react";
import { useNavigate, Link } from "react-router-dom";

const about = () => {
  return (
    <div>
        <section className="home-investment vision-col">
  <div className="container">
    <div className="row">      
      <div className="col-md-6">
        <div className="home-investment-right">
          <h3>Pioneering Financial Technology for a Connected World</h3>
          <p>Driven by the belief that access to financial information should be seamless and empowering to democratize financial information and provide the tools that professionals and casual users alike need to make informed decisions</p>         
        </div>
      </div>
      <div className="col-md-6">
        <div className="home-investment-left">
          <img src={`${process.env.PUBLIC_URL}/assets/images/about.png`} className="img-fluid" alt="" />
        </div>
      </div>
    </div>
  </div>
</section>
<section className="home-investment vision-col bgwhite">
  <div className="container">
    <div className="row">      
      <div className="col-md-6">
        <div className="home-investment-right">
          <h2>Empowering Financial Decisions Through Technology</h2>
          <p>Capovex stands at the forefront of fintech, transforming data into actionable insights that empowers decision-makers.</p>
          <ul>
            <li><span><img src={`${process.env.PUBLIC_URL}/assets/images/check.svg`} className="img-fluid" alt="" /></span>Innovative Financial Tools</li>
            <li><span><img src={`${process.env.PUBLIC_URL}/assets/images/check.svg`} className="img-fluid" alt="" /></span>Technology to seamlessly integrate with existing systems</li>
            <li><span><img src={`${process.env.PUBLIC_URL}/assets/images/check.svg`} className="img-fluid" alt="" /></span>Scalable financial tools that grow  With You Capital</li>
            <li><span><img src={`${process.env.PUBLIC_URL}/assets/images/check.svg`} className="img-fluid" alt="" /></span>crafting technology that transforms complex financial data</li>
          </ul>
        </div>
      </div>
      <div className="col-md-6">
        <div className="home-investment-left">
          <img src={`${process.env.PUBLIC_URL}/assets/images/about1.png`} className="img-fluid" alt="" />
        </div>
      </div>
    </div>    
  </div>
</section>

<section className="home-investment vision-col ">
  <div className="container">
    <div className="row">
      <div className="col-md-6">
        <div className="home-investment-left">
          <img src={`${process.env.PUBLIC_URL}/assets/images/about2.png`} className="img-fluid" alt="" />
        </div>
      </div>      
      <div className="col-md-6">
        <div className="home-investment-right">
          <h2>Continuously advancing our technologies & services</h2>
          <p>Our philosophy centers on delivering robust, precise financial tools & analytics that enable clients to achieve superior results..</p>
          <ul>
            <li><span><img src={`${process.env.PUBLIC_URL}/assets/images/check.svg`} className="img-fluid" alt="" /></span>We continually invest in research to keep our technology ahead</li>
            <li><span><img src={`${process.env.PUBLIC_URL}/assets/images/check.svg`} className="img-fluid" alt="" /></span>Our developments are driven by client needs</li>
            <li><span><img src={`${process.env.PUBLIC_URL}/assets/images/check.svg`} className="img-fluid" alt="" /></span>With a team of seasoned industry experts, we deliver deep insights </li>
            <li><span><img src={`${process.env.PUBLIC_URL}/assets/images/check.svg`} className="img-fluid" alt="" /></span>Dedicated to continuous improvement and innovation</li>
          </ul>
        </div>
      </div>      
    </div>    
  </div>
</section>

<section className="home-investment bgwhite">
  <div className="container">
    <div className="row">      
      <div className="col-md-6">
        <div className="home-investment-right">
          <h2>Committed to leading the charge in innovation</h2>
          <p>Our dedication to innovation is evident in our development of cutting-edge, AI-enhanced tools to redefine market analysis</p>
          <ul>
            <li><span><img src={`${process.env.PUBLIC_URL}/assets/images/check.svg`} className="img-fluid" alt="" /></span>Leader in Adoption of Modern Technology</li>
            <li><span><img src={`${process.env.PUBLIC_URL}/assets/images/check.svg`} className="img-fluid" alt="" /></span>We develop fast, and bring changes to ever evolving industry</li>
            <li><span><img src={`${process.env.PUBLIC_URL}/assets/images/check.svg`} className="img-fluid" alt="" /></span>Leading innovation with Open Source</li>
            <li><span><img src={`${process.env.PUBLIC_URL}/assets/images/check.svg`} className="img-fluid" alt="" /></span>Step Ahead of Curve with Capovex Core Technology</li>
          </ul>
        </div>
      </div>
      <div className="col-md-6">
        <div className="home-investment-left">
          <img src={`${process.env.PUBLIC_URL}/assets/images/about3.png`} className="img-fluid" alt="" />
        </div>
      </div>
    </div>    
  </div>
</section>

<section className="home-investment vision-col ">
  <div className="container">
    <div className="row">
      <div className="col-md-6">
        <div className="home-investment-left">
          <img src={`${process.env.PUBLIC_URL}/assets/images/about4.png`} className="img-fluid" alt="" />
        </div>
      </div>      
      <div className="col-md-6">
        <div className="home-investment-right">
          <h2>Global Influence with Local Expertise</h2>
          <p>Our solutions resonate on a global scale, yet we maintain a personalized approach with our clients offering tailored solutions</p>
          <ul>
            <li><span><img src={`${process.env.PUBLIC_URL}/assets/images/check.svg`} className="img-fluid" alt="" /></span>Our solutions are globally informed and locally applicable</li>
            <li><span><img src={`${process.env.PUBLIC_URL}/assets/images/check.svg`} className="img-fluid" alt="" /></span>We leverage a global network of financial experts</li>
            <li><span><img src={`${process.env.PUBLIC_URL}/assets/images/check.svg`} className="img-fluid" alt="" /></span>Solutions are customized to meet regulatory & market needs</li>
            <li><span><img src={`${process.env.PUBLIC_URL}/assets/images/check.svg`} className="img-fluid" alt="" /></span>Access from anywhere, any time, with the efficiency & security.</li>
          </ul>
        </div>
      </div>      
    </div>    
  </div>
</section>

<section className="trustedby">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <div className="trustedby-heading">
          <h2>Trusted by the Market's Best</h2>
          <p>Leaders in finance trust Capovex for our rigorous standards, innovative solutions, unwavering commitment to compliance and ethical integrity</p>
        </div>
      </div>
    </div>
  </div>
</section>


<section className="about-support">
  <div className="container">
    <div className="row">
      <div className="col-md-4">
        <div className="about-supportcard">
          <div className="about-supporticon">
            <img src={`${process.env.PUBLIC_URL}/assets/images/about-support1.svg`} className="img-fluid" alt="" />
          </div>
          <h2>Dedicated Support</h2>
          <p>We offer 24/7 support to ensure our clients can always rely on our tools to make critical financial decisions</p>
        </div>
      </div>
      <div className="col-md-4">
        <div className="about-supportcard">
          <div className="about-supporticon">
            <img src={`${process.env.PUBLIC_URL}/assets/images/about-support2.svg`} className="img-fluid" alt="" />
          </div>
          <h2>High Monetary Standards</h2>
          <p>We adhere strictly to international compliance regulations, ensuring all operations and solutions are transparent and secure</p>
        </div>
      </div>
      <div className="col-md-4">
        <div className="about-supportcard">
          <div className="about-supporticon">
            <img src={`${process.env.PUBLIC_URL}/assets/images/about-support3.svg`} className="img-fluid" alt="" />
          </div>
          <h2>Pioneering Solutions</h2>
          <p>We adhere strictly to international compliance regulations, ensuring all operations and solutions are transparent and secure</p>
        </div>
      </div>
    </div>
  </div>
</section>

<section className="ourpremier-sec">
  <div className="container">
    <div className="row">
      <div className="col-md-6">
        <div className="ourpremier-left">
          <h2>Explore Our Premier Financial Tools and Services</h2>
          <p>At Capovex, we provide a suite of advanced financial tools and services designed to enhance your market performance and decision-making capabilities. Discover how our innovative solutions can transform your approach to finance, making complex data analysis straightforward and actionable.</p>
        </div>
      </div>
      <div className="col-md-6">
        <div className="ourpremier-right">
          <ul>
            <li><span>AI Analyst</span>Harness the power of artificial intelligence to gain deep market insights</li>
            <li><span>Quantum Risk Management</span>Mitigate risks with our cutting-edge Quantum Risk Management system</li>
            <li><span>Market Tracker</span>Stay updated with global financial markets using our Market Tracker</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>

<section className="instagram-sec">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <div className="instagram-heading">
          <h2>Join us on Instagram</h2>
          <p>Join our Social Community to Stay tuned with our growth and latest developement as we bring to you the world for quantum finance</p>
          <a href="#">@Capovex<img src={`${process.env.PUBLIC_URL}/assets/images/check.svg`} className="img-fluid" alt="" /></a>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-md-4">
        <div className="instagramimg">
          <img src={`${process.env.PUBLIC_URL}/assets/images/instagramimg.png`} className="img-fluid" alt="" />
        </div>
      </div>
      <div className="col-md-4">
        <div className="instagramimg">
          <img src={`${process.env.PUBLIC_URL}/assets/images/instagramimg.png`} className="img-fluid" alt="" />
        </div>
      </div>
      <div className="col-md-4">
        <div className="instagramimg">
          <img src={`${process.env.PUBLIC_URL}/assets/images/instagramimg.png`} className="img-fluid" alt="" />
        </div>
      </div>
      <div className="col-md-4">
        <div className="instagramimg">
          <img src={`${process.env.PUBLIC_URL}/assets/images/instagramimg.png`} className="img-fluid" alt="" />
        </div>
      </div>
      <div className="col-md-4">
        <div className="instagramimg">
          <img src={`${process.env.PUBLIC_URL}/assets/images/instagramimg.png`} className="img-fluid" alt="" />
        </div>
      </div>
      <div className="col-md-4">
        <div className="instagramimg">
          <img src={`${process.env.PUBLIC_URL}/assets/images/instagramimg.png`} className="img-fluid" alt="" />
        </div>
      </div>

    </div>
    <div className="instagram-follow">
      <a href="#"><img src={`${process.env.PUBLIC_URL}/assets/images/Instagram.png`} className="img-fluid" alt="" />Follow us</a>
    </div>
  </div>
</section>


      
    </div>
  );
};

export default about;
