import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="footer-boxmain">
              <h3>OUTLOOK</h3>
              <p>At Capovex Research and Analysis, a vision to redefine the boundaries of financial research and democratize access to sophisticated investment tools. Join us at Capovex Research and Analysis, where finance meets the future, and where your investment journey is our guiding mission. Together, we are charting a course toward a more insightful and empowered financial world.</p>         
              <div className="form-group">
                  <label>Get the latest news and updates</label>
                  <input type="text" className="form-control" placeholder="Enter your email address" />
                  <button className="btn btn-info" type="submit">Suscribe</button>
              </div> 
              <ul>
                <li><Link to="#"><img src={`${process.env.PUBLIC_URL}/assets/images/f1.svg`} className="img-fluid" alt="" /></Link></li>
                <li><Link to="#"><img src={`${process.env.PUBLIC_URL}/assets/images/f2.svg`} className="img-fluid" alt="" /></Link></li>
                <li><Link to="#"><img src={`${process.env.PUBLIC_URL}/assets/images/f3.svg`} className="img-fluid" alt="" /></Link></li>
                <li><Link to="#"><img src={`${process.env.PUBLIC_URL}/assets/images/f4.svg`} className="img-fluid" alt="" /></Link></li>
                <li><Link to="#"><img src={`${process.env.PUBLIC_URL}/assets/images/f5.svg`} className="img-fluid" alt="" /></Link></li>
              </ul>
            </div>
          </div>
    
          <div className="col-md-2">
            <div className="footer-box">
              <h3>COMPANY</h3>
              <ul>
                <li><Link to="/policy/about-us">About Us</Link></li>
                <li><Link to="/vision">Our Vision & Values</Link></li>
                <li><Link to="#">Leadership</Link></li>
                <li><a href="#">Company Board</a></li>
                <li><a href="#">Work With us</a></li>
                <li><Link to="/policy/carbon-declaration">Carbon Declaration</Link></li>
              </ul>
            </div>
          </div>
    
          <div className="col-md-2">
            <div className="footer-box">
              <h3>RESOURCES</h3>
              <ul>
                <li><a href="#">Knowledge Center</a></li>
                <li><a href="#">Research</a></li>
                <li><Link to="/policy/follow-ship">Fellowship</Link></li>
                <li><a href="#">Calculators</a></li>
                <li><a href="#">Submit Article</a></li>
              </ul>
            </div>
          </div>
    
          <div className="col-md-2">
            <div className="footer-box">
              <h3>SUPPORT</h3>
              <ul>
                <li><Link to="#">Contact Us</Link></li>
                <li><Link to="/faq">FAQs</Link></li>
                <li><Link to="#">Grievances</Link></li>
                <li><Link to="/helpdisk">Help Desk</Link></li>
                <li><Link to="#">Vendor Registration</Link></li>
                <li><Link to="#">Partnerships</Link></li>
                <li><Link to="#">Research Support</Link></li>
              </ul>
            </div>
          </div>
    
          <div className="col-md-2">
            <div className="footer-box">
              <h3>FINE PRINT</h3>
              <ul>
                <li><Link to="/policy/privacy-policy">Privacy Policy</Link></li>
                <li><Link to="/policy/terms-of-service">Terms of Service</Link></li>
                <li><Link to="/policy/refund-policy">Refund Policy</Link></li>
                <li><Link to="/policy/pricing-policy">Pricing Policy</Link></li>
                <li><Link to="/policy/cookies-policy">Cookies Policy</Link></li>
                <li><Link to="/policy/abcp">ABAC Policy</Link></li>
                <li><Link to="/scam-alert">Scam Alert</Link></li>
                <li><Link to="/policy/disclaimers">Disclaimers</Link></li>
              </ul>
            </div>
          </div>
          <div className="col-md-12">
            <div className="copyright">
              <p>Proudly Made In India | Capovex Research and Analytics Limited © All Rights Reserved</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
