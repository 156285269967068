import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import apiFunctions from '../../Settings/Api';

const DynamicPolicy = () => {
  const { prefix } = useParams();
  const [policy, setPolicy] = useState({ title: '', content: '', updated_at: '' });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPolicy = async () => {
      try {
        setLoading(true);
        const response = await apiFunctions.getPolicyByPrefix(prefix);
        const { title, content, updated_at } = response;
        setPolicy({ title, content, updated_at });
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPolicy();
  }, [prefix]);


  if (error) {
    return <div className="text-center"><h3 className="p-5">Error: {error}</h3></div>;
  }

  const formattedDate = new Date(policy.updated_at).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  return (
    <section className="contentpage">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="content-heading">
              <h2>{policy.title}</h2>
              <p>Last updated: {formattedDate}</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="contentpage-design">
              <div dangerouslySetInnerHTML={{ __html: policy.content }} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DynamicPolicy;
