import React, { useState, useEffect } from 'react';
import apiFunctions from '../../Settings/Api';
import { useNavigate, Link } from 'react-router-dom';

function Upgrade() {
  const [planList, setPlanList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [success, setSuccess] = useState(null);
  const Selectedpalan = JSON.parse(localStorage.getItem('buyplan'));

  useEffect(() => {
    const checkUserLogin = async () => {
      const auth = localStorage.getItem('AuthToken');
      if (auth) {
        fetchPlanList();
      } else {
        navigate('/signup');
      }
    };

    const fetchPlanList = async () => {
      try {
        const response = await apiFunctions.getPlanList();
        if (response.plans && Array.isArray(response.plans)) {
          setPlanList(response.plans);
        } else {
          throw new Error('Invalid data format: Expected an array of plans.');
        }
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };
    checkUserLogin();
  }, [navigate]);

  const handleBuyNow = async (planId, planPrice) => {
    try {
      const AuthToken = localStorage.getItem('AuthToken');
      const response = await apiFunctions.buyPlan(planId, AuthToken);
      localStorage.setItem('buyplan', JSON.stringify(response.buyplan));
      setSuccess('Plan purchased successfully.');

      // Navigate to dashboard after 2 seconds
      setTimeout(() => {
        navigate('/user-dashboard');
      }, 2000);
    } catch (error) {
      setError(error);
    }
  };

  if (loading) return <div className='text-center'>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div>
      <section className="shotbg">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="content-heading">
                <h2>Upgrade Subscription</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      {success && <div className='text-center alert alert-success' role="alert">{success}</div>}
      <section className="signup-sec">
        <div className="container">
          <div className="row">
            {planList.map((plan) => (
              <div key={plan.id} className="col-md-4">
                <div className={`plan-card ${plan.id === 2 ? 'batter' : (plan.id === 3 ? 'best' : '')}`}>
                  <div className="iconbox"><div className="icon"><i className={`fa ${plan.id === 2 ? 'fa-diamond' : (plan.id === 3 ? 'fa-rocket' : 'fa-paper-plane')}`}></i></div></div>
                  <div className="plandesc">
                    <h3>{plan.name}</h3>
                    <h6>${plan.price}<span>/ mo</span></h6>
                    <ul>
                      <li>{plan.description}</li>
                    </ul>
                    {Selectedpalan && Selectedpalan.planid === plan.id ? (
                      <button type='button' className="btn btn-info">Subscribed</button>
                    ) : (
                      <button type='button' onClick={() => handleBuyNow(plan.id, plan.price)} className="btn btn-info">Buy Now</button>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Upgrade;
