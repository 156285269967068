import React, { useState, useEffect } from 'react';
import apiFunctions from '../../Settings/Api';
import Sidebar from '../Dashboard/Sidebar';
import AuthCheck from '../../Auth/AuthCheck';

const UserAccount = () => {
  const [userData, setUserData] = useState({
    id: '',
    first_name: '',
    last_name: '',
    phone_number: '',
    email: '',
  });
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const storedData = localStorage.getItem('user');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setUserData({
        ...userData,
        id: parsedData.id,
        first_name: parsedData.first_name,
        last_name: parsedData.last_name,
        phone_number: parsedData.phone_number,
        email: parsedData.email,
      });
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await apiFunctions.updateUserProfile(userData);
      localStorage.setItem('user', JSON.stringify(userData));
      setSuccessMessage('Profile updated successfully.');
      setErrors({}); // Clear any previous errors
      setErrorMessage(''); // Clear error message
    } catch (error) {
      console.error('Error updating user data:', error.response);

      // Check if there is an error response and error response data
      const errorData = error.response?.data;
      if (errorData && typeof errorData === 'object') {
        // Set the error message from the backend
        setErrorMessage(errorData.message || 'Failed to update user profile.');
        // Set the detailed errors if available
        setErrors(errorData.errors || {});
      } else {
        setErrorMessage('Failed to update user profile.');
        setErrors({}); // Clear any previous errors
      }

      setSuccessMessage(''); // Clear success message
    }
  };

  return (
    <AuthCheck>
      <div>
        <section className="shotbg">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="content-heading">
                  <h2>My Account</h2>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="signup-sec">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-4">
              <Sidebar />
              </div>
              <div className="col-lg-9 col-md-8">
                <div className="dashboard-view">
                  <div className="tab-content">
                    <div id="tabs1" className="tab-pane active">
                    <div className="myaccounttab">
                        <div className="form-design">
                          <h3 className="tabtitle">My Account</h3> 
                            {successMessage && <div className="alert alert-success">{successMessage}</div>}
                            {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                            <form onSubmit={handleSubmit}>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>First Name</label>
                                    <input type="text" className={`form-control ${errors.first_name ? 'is-invalid' : ''}`} id="firstName" name="first_name" value={userData.first_name} onChange={handleChange} />
                                  </div>
                                  {errors.first_name && <div className="invalid-feedback">{errors.first_name}</div>}
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Last Name</label>
                                    <input type="text" className={`form-control ${errors.last_name ? 'is-invalid' : ''}`} id="lastName" name="last_name" value={userData.last_name} onChange={handleChange} />
                                  </div>
                                  {errors.last_name && <div className="invalid-feedback">{errors.last_name}</div>}
                                </div>
                              </div>
                              <div className="form-group">
                                <label>Email Address</label>
                                  <input type="email" className={`form-control ${errors.email ? 'is-invalid' : ''}`} id="email" name="email" value={userData.email} onChange={handleChange} />
                                  {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                              </div>
                              <div className="form-group">
                                <label>Phone Number</label>
                                <input type="text" className={`form-control ${errors.phone_number ? 'is-invalid' : ''}`} id="phoneNumber" name="phone_number" value={userData.phone_number} onChange={handleChange} />
                                {errors.phone_number && <div className="invalid-feedback">{errors.phone_number}</div>}  
                              </div>
                              <div className="form-group loginpage-btn">
                                <input type="submit" value="Save Changes" className="btn btn-info" />
                              </div>
                            </form>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </AuthCheck>
  );
};

export default UserAccount;
