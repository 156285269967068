import React from 'react';
import Sidebar from '../Dashboard/Sidebar';
import AuthCheck from '../../Auth/AuthCheck';

const OrderManagement = () => {
  return (
    <AuthCheck>
      <div>
        <section className="shotbg">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="content-heading">
                  <h2>Order Management</h2>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="signup-sec">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-4">
                <Sidebar />
              </div>
              <div className="col-lg-9 col-md-8">
                <div className="dashboard-view">
                  <div className="tab-content">
                    <div id="tabs1" className="tab-pane active">
                    <div className="tab-transactions">
                        <h3 className="tabtitle">Order Management</h3>
                          <div className="tabledesign">
                                    <div className="table-responsive">
                                      <table className="table dt-responsive categories_table">
                                        <thead>
                                            <tr>
                                              <th >Plan Name</th>
                                              <th >Payment ID</th>
                                              <th >Payment Status</th>
                                              <th >Amount</th>
                                              <th >Date</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td>Good</td>
                                              <td>55566TTTTGGGGT</td>
                                              <td className="paymentstatus"><span className="pending">Pending</span></td>
                                              <td>$30</td>
                                              <td>05/09/2024</td>                                      
                                            </tr>
                                            <tr>
                                              <td>Good</td>
                                              <td>55566TTTTGGGGT</td>
                                              <td className="paymentstatus"><span className="successful">Successful</span></td>
                                              <td>$30</td>
                                              <td>05/09/2024</td>                                      
                                            </tr>
                                            <tr>
                                              <td>Good</td>
                                              <td>55566TTTTGGGGT</td>
                                              <td className="paymentstatus"><span className="pending">Pending</span></td>
                                              <td>$30</td>
                                              <td>05/09/2024</td>                                      
                                            </tr>
                                            <tr>
                                              <td>Good</td>
                                              <td>55566TTTTGGGGT</td>
                                              <td className="paymentstatus"><span className="successful">Successful</span></td>
                                              <td>$30</td>
                                              <td>05/09/2024</td>                                      
                                            </tr>
                                            <tr>
                                              <td>Good</td>
                                              <td>55566TTTTGGGGT</td>
                                              <td className="paymentstatus"><span className="pending">Pending</span></td>
                                              <td>$30</td>
                                              <td>05/09/2024</td>                                      
                                            </tr>
                                            <tr>
                                              <td>Good</td>
                                              <td>55566TTTTGGGGT</td>
                                              <td className="paymentstatus"><span className="successful">Successful</span></td>
                                              <td>$30</td>
                                              <td>05/09/2024</td>                                      
                                            </tr>

                                          </tbody>
                                        </table>
                                    </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </AuthCheck>
  );
};

export default OrderManagement;
