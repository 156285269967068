import React from "react";

const our_team = () => {
  return (
    <section className="ourteam-sec">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="content-heading capitalizetext">
            <h2>Meet Our Talented Team</h2>
            <h3>Who make everything possible in Capovex</h3>
          </div>
        </div>
      </div>
  
      <div className="row">
        <div className="col-md-6">
          <div className="ourteam-card">
            <div className="ourteam-img">
               <img src={`${process.env.PUBLIC_URL}/assets/images/team.jpg`} className="img-fluid" alt="" />
            </div>
            <div className="ourteam-desc">
               <h3>JANHAVI SINGHAL</h3>
               <h4>CEO & Founder</h4>
               <p>Lorem ipsum dolor sit amet de consecte adipiscing elit ametole hendrerit pretium nulla sed.</p>
               <ul>
                 <li><a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                 <li><a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
               </ul>
            </div>
          </div>
        </div>
  
        <div className="col-md-6">
          <div className="ourteam-card">
            <div className="ourteam-img">
               <img src={`${process.env.PUBLIC_URL}/assets/images/team.jpg`} className="img-fluid" alt="" />
            </div>
            <div className="ourteam-desc">
               <h3>JATIN JAYANT MOHGAONKAR</h3>
               <h4>VP of Finance</h4>
               <p>Lorem ipsum dolor sit amet de consecte adipiscing elit ametole hendrerit pretium nulla sed.</p>
               <ul>
                 <li><a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                 <li><a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
               </ul>
            </div>
          </div>
        </div>
  
        <div className="col-md-6">
          <div className="ourteam-card">
            <div className="ourteam-img">
               <img src={`${process.env.PUBLIC_URL}/assets/images/team.jpg`} className="img-fluid" alt="" />
            </div>
            <div className="ourteam-desc">
               <h3>PRATEEK AGGARWAL</h3>
               <h4>VP of Product</h4>
               <p>Lorem ipsum dolor sit amet de consecte adipiscing elit ametole hendrerit pretium nulla sed.</p>
               <ul>
                 <li><a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                 <li><a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
               </ul>
            </div>
          </div>
        </div>
  
        <div className="col-md-6">
          <div className="ourteam-card">
            <div className="ourteam-img">
               <img src={`${process.env.PUBLIC_URL}/assets/images/team.jpg`} className="img-fluid" alt="" />
            </div>
            <div className="ourteam-desc">
               <h3>PRIYANK SINGHAL</h3>
               <h4>VP of HR</h4>
               <p>Lorem ipsum dolor sit amet de consecte adipiscing elit ametole hendrerit pretium nulla sed.</p>
               <ul>
                 <li><a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                 <li><a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
               </ul>
            </div>
          </div>
        </div>
  
  
        <div className="col-md-6">
          <div className="ourteam-card">
            <div className="ourteam-img">
               <img src={`${process.env.PUBLIC_URL}/assets/images/team.jpg`} className="img-fluid" alt="" />
            </div>
            <div className="ourteam-desc">
               <h3>ABHISHEK ARORA</h3>
               <h4>VP of Product</h4>
               <p>Lorem ipsum dolor sit amet de consecte adipiscing elit ametole hendrerit pretium nulla sed.</p>
               <ul>
                 <li><a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                 <li><a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
               </ul>
            </div>
          </div>
        </div>
  
        <div className="col-md-6">
          <div className="ourteam-card">
            <div className="ourteam-img">
               <img src={`${process.env.PUBLIC_URL}/assets/images/team.jpg`} className="img-fluid" alt="" />
            </div>
            <div className="ourteam-desc">
               <h3>PRANITI SINGHAL</h3>
               <h4>VP of HR</h4>
               <p>Lorem ipsum dolor sit amet de consecte adipiscing elit ametole hendrerit pretium nulla sed.</p>
               <ul>
                 <li><a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                 <li><a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
               </ul>
            </div>
          </div>
        </div>
  
        <div className="col-md-6">
          <div className="ourteam-card">
            <div className="ourteam-img">
               <img src={`${process.env.PUBLIC_URL}/assets/images/team.jpg`} className="img-fluid" alt="" />
            </div>
            <div className="ourteam-desc">
               <h3>Christopher White</h3>
               <h4>VP of Product</h4>
               <p>Lorem ipsum dolor sit amet de consecte adipiscing elit ametole hendrerit pretium nulla sed.</p>
               <ul>
                 <li><a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                 <li><a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
               </ul>
            </div>
          </div>
        </div>
  
        <div className="col-md-6">
          <div className="ourteam-card">
            <div className="ourteam-img">
               <img src={`${process.env.PUBLIC_URL}/assets/images/team.jpg`} className="img-fluid" alt="" />
            </div>
            <div className="ourteam-desc">
               <h3>Emily Miller</h3>
               <h4>VP of HR</h4>
               <p>Lorem ipsum dolor sit amet de consecte adipiscing elit ametole hendrerit pretium nulla sed.</p>
               <ul>
                 <li><a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                 <li><a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
               </ul>
            </div>
          </div>
        </div>
  
  
      </div>
  
  
    </div>
  </section>
  );
};

export default our_team;
