import axios from 'axios';

const API_URL = 'https://admin.capovex.in/api/v1';

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// API functions
const apiFunctions = {
  // User registration
  registerUser: async (userData) => {
    try {
      const response = await api.post('/register', userData);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message || 'Registration failed. Please try again.');
    }
  },

  // User login
  loginUser: async (credentials) => {
    try {
      const response = await api.post('/login', credentials);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message || 'Login failed. Please check your credentials.');
    }
  },
  
  // User logout
  logoutUser: async (AuthToken) => {
    try {
      const response = await api.post('/logout', null, {
        headers: {
          Authorization: `Bearer ${AuthToken}`,
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message || 'Logout failed.');
    }
  },

  

  // Fetch user profile
  getUserProfile: async () => {
    try {
      const response = await api.get('/user-profile');
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message || 'Failed to fetch user profile.');
    }
  },

  // Update user profile
  updateUserProfile: async (userData) => {
    try {
      const response = await api.put(`/users/${userData.id}`, userData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('AuthToken')}`,
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message || 'Failed to update user profile.');
    }
  },

  // Fetch list of users
  getUsersList: async () => {
    try {
      const response = await api.get('/users');
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message || 'Failed to fetch users list.');
    }
  },

  // Delete user by ID
  deleteUser: async (userId) => {
    try {
      const response = await api.delete(`/users/${userId}`);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message || 'Failed to delete user.');
    }
  },

  //get all the plans 

  getPlanList: async () => {
    try {
      const response = await api.get('/plans');
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message || 'Failed to fetch plans list.');
    }
  },
  
  buyPlan: async (planId,AuthToken) => {
    try {
      const response = await api.post('/buyplans', { planId }, {
        headers: {
          Authorization: `Bearer ${AuthToken}`,
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message || 'Failed to buy plan.');
    }
  },
  
  CancelPlan: async (AuthToken) => {
    try {
      const response = await api.post('/cancelplan', null, {
        headers: {
          Authorization: `Bearer ${AuthToken}`,
        },
      });
      return response.data; // Assuming you expect response.data
    } catch (error) {
      throw new Error(error.response.data.message || 'Failed to cancel plan.');
    }
  },
  
  
  //getUserTransactionsList
  getUserTransactionsList: async (AuthToken,userId) => {
    try {
      const response = await api.get(`/getUserTransactions_list?userId=${userId}`,{
        headers: {
          Authorization: `Bearer ${AuthToken}`,
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message || 'Failed to get Transactions list.');
    }
  },

  //changepassword
  changePassword: async (AuthToken, currentPassword, newPassword) => {
    try {
      const response = await api.post('/change-password', {
        current_password: currentPassword,
        new_password: newPassword,
      }, {
        headers: {
          Authorization: `Bearer ${AuthToken}`,
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message || 'Failed to change password.');
    }
  },

  //sendmailforgetpassword
  sendmailforgetpassword: async (email) => {
    try {
      const response = await api.post('/sendmail-forgetpassword', email);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message || 'Failed to  send mail.');
    }
  },
    
  //getPolicyByPrefix
  getPolicyByPrefix: async (prefix) => {
    try {
      const response = await api.get(`/policy/${prefix}`);
      if (!response || !response.data) {
        throw new Error('No data received');
      }
      if (response.headers['content-type'] !== 'application/json') {
        throw new Error('Expected JSON response');
      }
      return response.data;
    } catch (error) {
      console.error('Error fetching policy:', error);
      const errorMessage = error.response?.data?.message || 'Failed to fetch policy.';
      throw new Error(errorMessage);
    }
  },

  //getContentinternalList
  getContentinternalList: async (params) => {
    try {
      const response = await api.get(`content-internal-list`, { params });
      return response;
    } catch (error) {
      throw new Error(error.response.data.message || 'Failed to fetch content-internal-list.');
    }
  },
 
  //getContentDetail
  getContentDetail: async (id) => {
    try {
      const response = await api.get(`/content-internal-Detail`, { params: { id } });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message || 'Failed to fetch content-internal-Detail.');
    }
  },
  
  //getContentByCategoryId
  getContentByCategoryId : async (CategoryId) => {
    try {
      const response = await api.get(`/content-internal-by-category`, { params: { CategoryId } });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message || 'Failed to fetch content-internal-by-Category.');
    }
  },

};

export default apiFunctions;
