import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import apiFunctions from '../Settings/Api';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

function SignUp() {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    password: '',
  });
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Retrieve planId and planPrice from state or default to null
  const { planId = null, planPrice = null } = location.state || {};

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  useEffect(() => {
    const auth = localStorage.getItem('AuthToken');
    const authUserFound = localStorage.getItem('user');
    if (auth && authUserFound) {
      navigate('/');
    }
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};
    const { first_name, last_name, email, phone_number, password } = formData;

    if (!first_name.trim()) {
      newErrors.first_name = 'First name is required';
    }
    if (!last_name.trim()) {
      newErrors.last_name = 'Last name is required';
    }
    if (!email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!isValidEmail(email)) {
      newErrors.email = 'Invalid email format';
    }
    if (!phone_number.trim()) {
      newErrors.phone_number = 'Phone number is required';
    }
    if (!password.trim()) {
      newErrors.password = 'Password is required';
    }
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      try {
        const response = await apiFunctions.registerUser({
          ...formData,
          planId, // Include planId in the request
          planPrice, // Include planPrice in the request
        });
        localStorage.setItem('user', JSON.stringify(response.user));
        localStorage.setItem('AuthToken', response.token);
        if(response.buyplan)
          {
            localStorage.setItem('buyplan', JSON.stringify(response.buyplan));
            navigate('/user-dashboard');
            location.reload('/user-dashboard');
          }else{
            localStorage.setItem('buyplan', false);
            navigate('/plan');
            location.reload('/plan');
          }
      } catch (error) {
        setErrors({ general: error.message });
      }
    }
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div>
      <section className="signup-sec signuppage">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="signup-left">
                <h2>Capovex Core Systems</h2>
                <p>Restricted Access. If you have reached here mistakenly. Leave..!!</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-design">
                <div className="form-design-heading">
                  <h2>Create an account</h2>
                  <h3>Welcome Back</h3>
                </div>
                <form onSubmit={handleSubmit}>
                    {errors.general && (
                      <div className="text-center alert alert-danger" role="alert">
                        {errors.general}
                      </div>
                    )}
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <div className="form-groupimg">
                          <input
                            className={`form-control ${errors.first_name ? 'is-invalid' : ''}`}
                            type="text"
                            id="first_name"
                            onChange={handleChange}
                            name="first_name"
                            placeholder="Enter first name"
                          />
                          <i className="la la-user"></i>
                        </div>
                      </div>
                      {errors.first_name && <div className="invalid-feedback">{errors.first_name}</div>}
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <div className="form-groupimg">
                          <input
                            className={`form-control ${errors.last_name ? 'is-invalid' : ''}`}
                            type="text"
                            id="last_name"
                            onChange={handleChange}
                            name="last_name"
                            placeholder="Enter last name"
                          />
                          <i className="la la-user"></i>
                        </div>
                      </div>
                      {errors.last_name && <div className="invalid-feedback">{errors.last_name}</div>}
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-groupimg">
                      <input
                        className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                        type="email"
                        id="email"
                        onChange={handleChange}
                        name="email"
                        placeholder="Enter Email Address"
                      />
                      <i className="la la-envelope-o"></i>
                    </div>
                    {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                  </div>
                  <div className="form-group">
                    <div className="form-groupimg">
                      <input
                        className={`form-control ${errors.phone_number ? 'is-invalid' : ''}`}
                        type="text"
                        id="phone_number"
                        onChange={handleChange}
                        name="phone_number"
                        placeholder="Enter Phone number"
                      />
                      <i className="la la-phone"></i>
                    </div>
                    {errors.phone_number && <div className="invalid-feedback">{errors.phone_number}</div>}
                  </div>
                  <div className="form-group">
                    <div className="form-groupimg">
                      <input
                        className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                        type={showPassword ? 'text' : 'password'}
                        id="password"
                        onChange={handleChange}
                        name="password"
                        placeholder="Enter password"
                      />
                      <i className="la la-lock"></i>
                    </div>
                    {errors.password && <div className="invalid-feedback">{errors.password}</div>}
                  </div>
                  <div className="form-group form-check checkdesign">
                    <label className="form-check-label">
                      <input className="form-check-input" name="terms_privacy" type="checkbox" />I agree with <Link to="/terms-of-service">Terms of Use</Link> and <Link to="/pricing-policy">Privacy Policy</Link>..
                    </label>
                  </div>
                  <div className="form-group loginpage-btn">
                    {/* Conditional rendering of submit button based on planId and planPrice */}
                    {planId && planPrice ? (
                      <input type="submit" value="Submit" className="btn btn-info width100" />
                    ) : (
                      <input type="submit" value="Sign Up" className="btn btn-info width100" />
                    )}
                  </div>
                </form>
                <div className="form-group dontaccount">
                  <p>Already have an account? <Link to="/login">Sign In</Link></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SignUp;
