import React, { useState } from 'react';
import apiFunctions from '../Settings/Api'; // Make sure to import your API functions

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    if (!email) {
      setError('Please enter an email address');
      return;
    }

    try {
      const response = await apiFunctions.sendmailforgetpassword({ email }); // Update to use your actual API call
      if (response.success) {
        setMessage('Password reset link has been sent to your email');
      } else {
        setError('Failed to send password reset link');
      }
    } catch (err) {
      setError(err.message || 'An error occurred. Please try again later.');
    }
  };

  return (
    <div>
      <section className="signup-sec signuppage">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="signup-left">
                <h2>Capovex Core Systems</h2>
                <p>Restricted Access. If you have reached here mistakenly. Leave..!!</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-design">
                <div className="form-design-heading">
                  <h2>Forgot Password</h2>
                </div>
                <form onSubmit={handleSubmit}>
                    {message && <div className="alert alert-success">{message}</div>}
                    {error && <div className="alert alert-danger">{error}</div>}
                  <div className="form-group">
                    <div className="form-groupimg">
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter Email Address"
                      />
                      <i className="la la-envelope-o"></i>
                    </div>
                  </div>
                  <div className="form-group loginpage-btn">
                    <input type="submit" value="Submit" className="btn btn-info width100" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ForgotPassword;
