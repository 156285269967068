import React from 'react';

const UserDashboard = () => {
  return (
    <div className="dashboard-design">
      <div className="dashboard-message">
        <h3>Welcome to your Dashboard</h3>
        <p>This is a simple example of a dashboard page.</p>
      </div>
    </div>
  );
};

export default UserDashboard;
