import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ContentPreview = () => {
  const ApibaseUrl = 'http://127.0.0.1:8000';
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [tag, setTag] = useState('');
  const [keyword, setKeyword] = useState('');
  const [categories, setCategories] = useState([]);
  const [AuthorName, setAuthorName] = useState('');
  const [imagePath, setImagePath] = useState('');
  const [showFullContent, setShowFullContent] = useState(false);
  const wordLimit = 500; // Set your word limit for preview content

  useEffect(() => {
    setTitle(queryParams.get('title') || '');
    setContent(queryParams.get('content') || '');
    setTag(queryParams.get('tag') || '');
    setKeyword(queryParams.get('keyword') || '');
    setCategories((queryParams.get('categories') || '').split(','));
    setAuthorName(queryParams.get('author_name') || '');
    setImagePath(queryParams.get('image_path') || '');
  }, [location.search]);

  // Function to count words in content
  const countWords = (text) => {
    if (!text) return 0;
    return text.split(/\s+/).length;
  };

  // Check if content exceeds word limit
  const isContentTooLong = countWords(content) > wordLimit;

  // Function to toggle showing full content
  const toggleShowFullContent = () => {
    setShowFullContent(!showFullContent);
  };

  const formatCreatedAt = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  return (
    <div>
      <section
        className="content-banner"
        style={{
          background: `url('${ApibaseUrl}/${imagePath ? imagePath : `${process.env.PUBLIC_URL}/assets/images/contentbg.jpg`}')`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="content-title">
                <h2>{title}</h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="contentnewpage">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="contentpage-design contentnewpage-left">
                {/* Render content with limited words */}
                <div dangerouslySetInnerHTML={{ __html: content?.split(/\s+/).slice(0, wordLimit).join(' ') }} />

                {/* Render Read More button conditionally */}
                {isContentTooLong && !showFullContent && (
                  <div className="contentnewpage-readmore">
                    <button className="btn btn-info btndark" onClick={toggleShowFullContent}>
                      Read More{' '}
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/readmore.svg`}
                        className="img-fluid"
                        alt=""
                      />
                    </button>
                  </div>
                )}

                {/* Render full content if showFullContent is true */}
                {showFullContent && (
                  <div dangerouslySetInnerHTML={{ __html: content }} />
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div className="contentnewpage-right">
                <div className="contentnewpage-bedge">
                  <span>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/heart.svg`}
                      className="img-fluid"
                      alt=""
                    />
                    24.5k
                  </span>
                  <span>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/eye.svg`}
                      className="img-fluid"
                      alt=""
                    />
                    50k
                  </span>
                  <span>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/paperplane.svg`}
                      className="img-fluid"
                      alt=""
                    />
                    206
                  </span>
                </div>
                <ul className="contentnewpage-info">
                  <li>
                    <label>Publication Date</label>
                    <span>{formatCreatedAt(new Date())}</span>
                  </li>
                  <li>
                    <label>Category</label>
                    <span>{categories.join(', ')}</span>
                  </li>
                  <li>
                    <label>Reading Time</label>
                    <span>10 Min</span>
                  </li>
                  <li>
                    <label>Author Name</label>
                    <span>{AuthorName}</span>
                  </li>
                  <li>
                    <label>Tag's</label>
                    <span>{tag}</span>
                  </li>
                  <li>
                    <label>Keyword's</label>
                    <span>{keyword}</span>
                  </li>
                </ul>
                <h3>Table of Contents</h3>
                <div className="contentnewpage-list">
                  <ul>
                    <li>
                      <a href="#">Introduction</a>
                    </li>
                    <li>
                      <a href="#">AI in Diagnostic Imaging</a>
                    </li>
                    <li>
                      <a href="#">
                        Predictive Analytics and Disease Prevention
                      </a>
                    </li>
                    <li>
                      <a href="#">Personalized Treatment Plans</a>
                    </li>
                    <li>
                      <a href="#">Drug Discovery and Research</a>
                    </li>
                    <li>
                      <a href="#">AI in Telemedicine</a>
                    </li>
                    <li>
                      <a href="#">Ethical Considerations</a>
                    </li>
                    <li>
                      <a href="#">The Future of AI in Healthcare</a>
                    </li>
                    <li>
                      <a href="#">Conclusion</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContentPreview;
