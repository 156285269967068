import React from "react";

const Connect = () => {
  return (
    <div>
      <section className="connect-hero">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="headingsec">
                <h2>We’d Love To hear From You</h2>
                <p>
                  Whether you’re curious about features, a free trial, or even
                  press. We’re ready to answer any and all questions.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="connect-card">
                <span className="connect-border border1"></span>
                <h3>Sales</h3>
                <p>
                  Wondering if Capovex is the right tool for you? Are you
                  looking for a reliable pocket-friendly tool for research and
                  analytics for marketable securities? Chat with our team to see
                  if there’s a fit.
                </p>
                <div className="connect-cardbtn">
                  <a href="#" className="btn btn-info">
                    Talk to an Agent
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="connect-card">
                <span className="connect-border border2"></span>
                <h3>Support</h3>
                <p>
                  Need a hand using Capovex Manthan, Drishti or managing your
                  account? Are you facing any technical troubles? Chat with a
                  real, live human or self-serve using our Help Center.
                </p>
                <div className="connect-cardbtn">
                  <a href="mailto:support@capovex.in" className="btn btn-info">
                    support@capovex.in
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="connect-card">
                <span className="connect-border border3"></span>
                <h3>Media</h3>
                <p>
                  We love working with journalists to share compelling stories,
                  Market insights and Ideas. Send us a note and our media and
                  Communications Manager will be in touch at the earliest
                </p>
                <div className="connect-cardbtn">
                  <a href="mailto:media@capovex.in" className="btn btn-info">
                    media@capovex.in
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="connect-card">
                <span className="connect-border border4"></span>
                <h3>Partnerships</h3>
                <p>
                  We’re into co-marketing with awesome brands. Whether you are
                  local or global, A Shark Tank product or Local E Cell Startup,
                  Fill out the form here, and our Partnerships Manager will
                  circle back.
                </p>
                <div className="connect-cardbtn">
                  <a href="#" className="btn btn-info">
                    Fill up the Form
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="located-sec"
        style={{ background: "url('assets/images/locatedbg.jpg')" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div className="located-left">
                <div className="headingsec">
                  <h2>Our office is located in the Millennium City of INDIA</h2>
                  <p>
                    At Capovex Research and Analytics, we treasure clarity and
                    precision just as we cherish our roots in the vibrant and
                    historic Gurugram—once known as the Town of Guru, a place
                    steeped in the sagas of the Mahabharata, where teachers
                    (gurus) imparted wisdom that transcended the ages.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="located-right">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/map.png`}
                  className="img-fluid"
                  alt="Map"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="location-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="location-left">
                <p>
                  We're just one click away to help you elevate your financial
                  strategies from solid to exceptional. Fill in the form to
                  share more details about your analytics needs. Or your
                  favorite financial guru — we're all ears either way. We’d love
                  to discuss how we can assist you
                </p>
                <p>
                  <strong>Capovex Research & Analytics</strong>
                  <br />
                  2nd Floor, Silverton Tower, Core A Wing <br />
                  Golf Course Extension Road,
                  <br />
                  Sector 50, Gurugram
                  <br />
                  Haryana, INDIA 122018
                </p>
                <p>
                  <strong>
                    Feel free to reach out via email, or better yet, stop by our
                    office in Gurugram. We're excited to show you how our
                    insights can help shape your financial future.
                  </strong>
                </p>
                <p>
                  Capovex is regularly conducting fellowship meet and greet in
                  cities across the globe. Stay tuned to our{" "}
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="location-right">
                <form>
                  <div className="form-group">
                    <label>Full Name*</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter your Full Name"
                    />
                  </div>
                  <div className="form-group">
                    <label>Phone Number*</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter your Mobile Number"
                    />
                  </div>
                  <div className="form-group">
                    <label>Email*</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter your official EMAIL ID"
                    />
                  </div>
                  <div className="form-group">
                    <label>Message*</label>
                    <textarea
                      className="form-control"
                      rows="5"
                      placeholder="Write your detailed message here"
                    ></textarea>
                  </div>
                  <div className="form-group">
                    <div className="recaptcha">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/reCAPTCHA.png`}
                        className="img-fluid"
                        alt="reCAPTCHA"
                      />
                    </div>
                  </div>
                  <button type="submit" className="btn btn-info">
                    Send
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/sendicon.svg`}
                      className="img-fluid"
                      alt="Send Icon"
                    />
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Connect;
