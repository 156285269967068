import React from 'react';
import UserDashboard from '../Dashboard/UserDashboard';
import Sidebar from '../Dashboard/Sidebar';
import AuthCheck from '../../Auth/AuthCheck';

const Dashboard = () => {
  return (
    <AuthCheck>
      <div>
        <section className="shotbg">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="content-heading">
                  <h2>Dashboard</h2>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="signup-sec">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-4">
                <Sidebar />
              </div>
              <div className="col-lg-9 col-md-8">
                <div className="dashboard-view">
                  <div className="tab-content">
                    <div id="tabs1" className="tab-pane active">
                      <UserDashboard />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </AuthCheck>
  );
};

export default Dashboard;
