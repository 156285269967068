import React from "react";

const faq = () => {
  return (
    <section className="faqpage">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="content-heading capitalizetext">
              <h2>FAQs</h2>
              <h3>Frequently Asked Queries Answered</h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <div className="faqpage-left">
              <div id="accordion">
                <div className="card">
                  <div className="card-header">
                    <a
                      className="btn"
                      data-bs-toggle="collapse"
                      href="#collapse1"
                    >
                      What is Capovex?
                    </a>
                  </div>
                  <div
                    id="collapse1"
                    className="collapse show"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <a
                      className="collapsed btn"
                      data-bs-toggle="collapse"
                      href="#collapse2"
                    >
                      How do I create an account on Capovex?
                    </a>
                  </div>
                  <div
                    id="collapse2"
                    className="collapse"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">
                      To create an account, click on the "Sign Up" button on the
                      homepage, fill in your details, and follow the
                      instructions to verify your email and set up your profile.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <a
                      className="collapsed btn"
                      data-bs-toggle="collapse"
                      href="#collapse3"
                    >
                      What are the key features of Manthan?
                    </a>
                  </div>
                  <div
                    id="collapse3"
                    className="collapse"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <a
                      className="collapsed btn"
                      data-bs-toggle="collapse"
                      href="#collapse4"
                    >
                      What is Drishti?
                    </a>
                  </div>
                  <div
                    id="collapse4"
                    className="collapse"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <a
                      className="collapsed btn"
                      data-bs-toggle="collapse"
                      href="#collapse5"
                    >
                      Is Capovex licensed by SEBI?
                    </a>
                  </div>
                  <div
                    id="collapse5"
                    className="collapse"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <a
                      className="collapsed btn"
                      data-bs-toggle="collapse"
                      href="#collapse6"
                    >
                      How do I subscribe to Drishti?
                    </a>
                  </div>
                  <div
                    id="collapse6"
                    className="collapse"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <a
                      className="collapsed btn"
                      data-bs-toggle="collapse"
                      href="#collapse7"
                    >
                      How secure is my data on Capovex?
                    </a>
                  </div>
                  <div
                    id="collapse7"
                    className="collapse"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <a
                      className="collapsed btn"
                      data-bs-toggle="collapse"
                      href="#collapse8"
                    >
                      Can I connect my existing Demat account to Manthan?
                    </a>
                  </div>
                  <div
                    id="collapse8"
                    className="collapse"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <a
                      className="collapsed btn"
                      data-bs-toggle="collapse"
                      href="#collapse9"
                    >
                      What types of reports does Capovex offer?
                    </a>
                  </div>
                  <div
                    id="collapse9"
                    className="collapse"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <a
                      className="collapsed btn"
                      data-bs-toggle="collapse"
                      href="#collapse10"
                    >
                      How often are the market reports updated?
                    </a>
                  </div>
                  <div
                    id="collapse10"
                    className="collapse"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <a
                      className="collapsed btn"
                      data-bs-toggle="collapse"
                      href="#collapse11"
                    >
                      Can I access Capovex on my mobile device?
                    </a>
                  </div>
                  <div
                    id="collapse11"
                    className="collapse"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <a
                      className="collapsed btn"
                      data-bs-toggle="collapse"
                      href="#collapse12"
                    >
                      What is the subscription cost for Drishti?
                    </a>
                  </div>
                  <div
                    id="collapse12"
                    className="collapse"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <a
                      className="collapsed btn"
                      data-bs-toggle="collapse"
                      href="#collapse13"
                    >
                      How can I contact customer support?
                    </a>
                  </div>
                  <div
                    id="collapse13"
                    className="collapse"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <a
                      className="collapsed btn"
                      data-bs-toggle="collapse"
                      href="#collapse14"
                    >
                      What payment methods are accepted?
                    </a>
                  </div>
                  <div
                    id="collapse14"
                    className="collapse"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <a
                      className="collapsed btn"
                      data-bs-toggle="collapse"
                      href="#collapse15"
                    >
                      Is there a trial period available for Drishti or Manthan?
                    </a>
                  </div>
                  <div
                    id="collapse15"
                    className="collapse"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <a
                      className="collapsed btn"
                      data-bs-toggle="collapse"
                      href="#collapse16"
                    >
                      How do I cancel my subscription?
                    </a>
                  </div>
                  <div
                    id="collapse16"
                    className="collapse"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <a
                      className="collapsed btn"
                      data-bs-toggle="collapse"
                      href="#collapse17"
                    >
                      Are there any tutorials available to help me use the
                      portal?
                    </a>
                  </div>
                  <div
                    id="collapse17"
                    className="collapse"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <a
                      className="collapsed btn"
                      data-bs-toggle="collapse"
                      href="#collapse18"
                    >
                      What languages are supported by Drishti?
                    </a>
                  </div>
                  <div
                    id="collapse18"
                    className="collapse"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <a
                      className="collapsed btn"
                      data-bs-toggle="collapse"
                      href="#collapse19"
                    >
                      How do I update my account information?
                    </a>
                  </div>
                  <div
                    id="collapse19"
                    className="collapse"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <a
                      className="collapsed btn"
                      data-bs-toggle="collapse"
                      href="#collapse20"
                    >
                      What should I do if I forget my password?
                    </a>
                  </div>
                  <div
                    id="collapse20"
                    className="collapse"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="faqpage-right">
              <h2>Guide to use Manthan</h2>
              <div className="guide-card">
                <div className="guide-card-img">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/guide-card-img1.jpg`}
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="guide-card-desc">
                  <h3>
                    <a href="#">Steps By Step Guide to Creating an Account</a>
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipiscing eli mattis
                    sit phasellus mollis sit aliquam sit nullam neque ultrices.
                  </p>
                  <div className="guide-card-info">
                    <span>Category</span>
                    <span>Jan 24, 2024</span>
                  </div>
                </div>
              </div>

              <div className="guide-card">
                <div className="guide-card-img">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/guide-card-img1.jpg`}
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="guide-card-desc">
                  <h3>
                    <a href="#">Navigating to various Manthan modules </a>
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipiscing eli mattis
                    sit phasellus mollis sit aliquam sit nullam neque ultrices.
                  </p>
                  <div className="guide-card-info">
                    <span>Guide</span>
                    <span>Jan 24, 2024</span>
                  </div>
                </div>
              </div>

              <div className="guide-card">
                <div className="guide-card-img">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/guide-card-img1.jpg`}
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="guide-card-desc">
                  <h3>
                    <a href="#">Edit, Pause or Restart Subscriptions</a>
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipiscing eli mattis
                    sit phasellus mollis sit aliquam sit nullam neque ultrices.
                  </p>
                  <div className="guide-card-info">
                    <span>Guide</span>
                    <span>Jan 24, 2024</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default faq;
