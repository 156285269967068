import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Sidebar = () => {
  const location = useLocation();

  return (
    <div className="dashboard-tab">
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <Link className={`nav-link ${location.pathname === '/user-dashboard' ? 'active' : ''}`} to="/user-dashboard">Dashboard</Link>
        </li>
        <li className="nav-item">
          <Link className={`nav-link ${location.pathname === '/user-account' ? 'active' : ''}`} to="/user-account">My Account</Link>
        </li>
        <li className="nav-item">
          <Link className={`nav-link ${location.pathname === '/user-transactions' ? 'active' : ''}`} to="/user-transactions">Transactions</Link>
        </li>
        <li className="nav-item">
          <Link className={`nav-link ${location.pathname === '/user-order-management' ? 'active' : ''}`} to="/user-order-management">Order Management</Link>
        </li>
        <li className="nav-item">
          <Link className={`nav-link ${location.pathname === '/user-change-password' ? 'active' : ''}`} to="/user-change-password">Change Password</Link>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
