import React from "react";

const ScamAlert = () => {
  return (
    <section className="contentpage">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="content-heading">
              <h2>SCAM ALERT</h2>
              <h3>Important Notice to All Users</h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-7">
            <div className="contentpage-design">
              <p>
                At Capovex Research and Analytics Pvt. Ltd., we prioritize the
                security and integrity of our services and the trust you place
                in us. Recently, it has come to our attention that certain
                individuals or entities might be using our brand name to create
                groups or channels across various social media platforms,
                including but not limited to WhatsApp, Telegram, Discord, and
                Slack.
              </p>

              <h2>Official Communication Channels</h2>
              <p>
                We would like to clarify to all our users and the public that
                Capovex Research and Analytics Pvt. Ltd. does not operate any
                official groups or channels on these platforms for the purpose
                of providing investment tips, stock recommendations, or
                portfolio management services. Our core function remains as a
                research and analytics company, and we strictly abide by the
                rules and regulations set by the Securities Exchange Board of
                India (SEBI).
              </p>

              <h2>Our Commitment</h2>
              <h3>Capovex does not endorse or engage in:</h3>

              <ul className="listnumber">
                <li>Trading suggestions</li>
                <li>Investment tips</li>
                <li>
                  Financial advisory services outside of our official platforms
                  and products.
                </li>
              </ul>

              <h2>Reporting Suspicious Activities</h2>
              <p>
                If you encounter any group, channel, or entity falsely using the
                Capovex brand name, we urge you to report it immediately using
                the form provided. Prompt reporting can help protect you and
                others from potential scams and contribute to the ongoing
                security of all users.
              </p>

              <h3>Stay Informed</h3>
              <p>
                To stay updated on legitimate information and announcements from
                Capovex, please refer to our official website and verified
                communication outlets. We value your vigilance and cooperation
                in helping us maintain a safe and trustworthy environment.
              </p>
              <p>
                For any questions or additional information, feel free to
                contact our support team at support@capovex.in.
              </p>
            </div>
          </div>

          <div className="col-md-5">
            <div className="location-right scam-alertright">
              <form>
                <div className="form-group">
                  <label>Link to Scam Platform</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    id=""
                  />
                  <span className="input-info">
                    Enter Link of Website, Telegram Channel or Whatsapp Group
                  </span>
                </div>
                <div className="form-group">
                  <label>Link to Scam Advertisements</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    id=""
                  />
                  <span className="input-info">
                    Is the Scammer Advertising? Share the Link of Advertisement.
                  </span>
                </div>
                <div className="form-group">
                  <label>
                    Have you Reported the Scammer to Cyber Cell / Police
                  </label>
                  <select className="form-control form-select">
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                  </select>
                  <span className="input-info">
                    Have you Reported the Scammer to Cyber Cell / Police
                  </span>
                </div>
                <div className="form-group">
                  <label>Did You suffer any financial losses</label>
                  <select className="form-control form-select">
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                  </select>
                  <span className="input-info">
                    Did You suffer any financial losses
                  </span>
                </div>
                <div className="form-group">
                  <label>
                    Details of the Scam and how did you find it is a scam
                  </label>
                  <textarea
                    className="form-control"
                    rows="5"
                    id="comment"
                    name="text"
                    placeholder="Write your detailed message here"
                  ></textarea>
                  <span className="input-info">This is the description area</span>
                </div>
                <div className="uploadgroup">
                  <div className="upload-heading">
                    <h3>Upload Evidences</h3>
                    <h4>You can upload up to 5 files max</h4>
                  </div>
                  <div className="uploadbox">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/upload.png`}
                      className="img-fluid"
                      alt=""
                    />
                    <p>Drag your file(s) to start uploading</p>
                    <div className="or">
                      <span>OR</span>
                    </div>
                    <label>
                      Browse files{" "}
                      <input
                        type="file"
                        className="form-control"
                        placeholder=""
                        id=""
                      />
                    </label>
                  </div>
                  <p className="upload-files">
                    Only support .jpg, .png and .svg and zip files
                  </p>
                </div>

                <div className="form-group">
                  <div className="recaptcha">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/reCAPTCHA.png`}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
                <button type="submit" className="btn btn-info">
                  Send
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/sendicon.svg`}
                    className="img-fluid"
                    alt=""
                  />
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ScamAlert;
