import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import apiFunctions from "../../Settings/Api";

function Header() {
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State to track login status
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the user is logged in
    const auth = localStorage.getItem("AuthToken");
    const authUserFound = localStorage.getItem("user");

    if (auth && authUserFound) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  const handleLogout = async () => {
    try {
      const AuthToken = localStorage.getItem("AuthToken");
      const response = await apiFunctions.logoutUser(AuthToken);
      if (response.status == 200) {
        localStorage.removeItem("AuthToken");
        localStorage.removeItem("user");
        localStorage.removeItem("buyplan");
        setIsLoggedIn(false);
        navigate("/");
      }
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  return (
    <header className="header-sec">
      <div className="topbar">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <ul>
                <li>
                  <span className="careticon">
                    <i className="fa fa-caret-down" aria-hidden="true"></i>
                  </span>
                  Nifty 50
                </li>
                <li>
                  <span className="careticon">
                    <i className="fa fa-caret-up" aria-hidden="true"></i>
                  </span>
                  Nifty NEXT 50
                </li>
                <li>
                  <span className="careticon">
                    <i className="fa fa-caret-up" aria-hidden="true"></i>
                  </span>
                  Nifty 500
                </li>
                <li>
                  <span className="careticon">
                    <i className="fa fa-caret-down" aria-hidden="true"></i>
                  </span>
                  India Vix
                </li>
                <li>
                  <span className="careticon">
                    <i className="fa fa-caret-up" aria-hidden="true"></i>
                  </span>
                  CRAL
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <nav className="navbar navbar-expand-lg bg-dark navbar-dark">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/logo.png`}
              className="img-fluid"
              alt=""
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapsibleNavbar"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="collapsibleNavbar">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Search"
              />
              <button className="btn btn-success" type="submit">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/search.png`}
                  className="img-fluid"
                  alt=""
                />
              </button>
            </div>
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <Link className="nav-link active" to="/features">
                  Features
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="pricing">
                  Pricing
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/contact-us">
                  Contact Us
                </Link>
              </li>
              {!isLoggedIn ? (
                <li className="nav-item login">
                  <Link className="nav-link" to="/login">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/codicon_account.svg`}
                      className="img-fluid"
                      alt="Login"
                    />
                    Login
                  </Link>
                </li>
              ) : null}
            </ul>
            {isLoggedIn ? (
              <ul className="navbar-nav ms-auto">
                <li className="nav-item dropdown dropdownprofile">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/client1.png`}
                      className="img-fluid"
                      alt=""
                    />
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <a className="dropdown-item username" href="#">
                        Aditya
                      </a>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/user-account">
                        <i className="la la-user"></i>My Account
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/plan">
                        <i className="la la-th-large"></i>Subscription
                      </Link>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={handleLogout}
                      >
                        <i className="la la-sign-out"></i>Logout
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            ) : null}
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
