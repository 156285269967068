import React from "react";

const helpdisk = () => {
  return (
    <div>
      <section class="help-sec">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="content-heading capitalizetext">
                <h2>Help Desk</h2>
                <h3>Need Support to use Capovex</h3>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search"
                  />
                  <button class="btn btn-success" type="submit">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/search.png`}
                      class="img-fluid"
                      alt=""
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="helpblog-sec">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="helpblog-card">
                <div class="helpblog-img">
                  <a href="#">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/helpblog.jpg`}
                      class="img-fluid"
                      alt=""
                    />
                  </a>
                </div>
                <div class="helpblog-desc">
                  <div class="helpblog-date">
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/calendar.png`}
                        class="img-fluid"
                        alt=""
                      />
                      Jan 16, 2024
                    </span>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/tag.png`}
                        class="img-fluid"
                        alt=""
                      />
                      Category
                    </span>
                  </div>
                  <h3>
                    <a href="#">
                      Web design trends 2023: Stay ahead of the curve
                    </a>
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipiscing eli mattis
                    sit phasellus mollis sit aliquam sit nullam neque ultrices.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6 helpblog-cardcol2">
              <div class="helpblog-card">
                <div class="helpblog-desc">
                  <div class="helpblog-date">
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/calendar.png`}
                        class="img-fluid"
                        alt=""
                      />
                      Jan 18, 2024
                    </span>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/tag.png`}
                        class="img-fluid"
                        alt=""
                      />
                      Category
                    </span>
                  </div>
                  <h3>
                    <a href="#">
                      Typography in web design: Enhancing UI/UX web apps
                    </a>
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipiscing eli mattis
                    sit phasellus mollis sit aliquam sit nullam neque ultrices.
                  </p>
                </div>
              </div>
              <div class="helpblog-card">
                <div class="helpblog-desc">
                  <div class="helpblog-date">
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/calendar.png`}
                        class="img-fluid"
                        alt=""
                      />
                      Jan 20, 2024
                    </span>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/tag.png`}
                        class="img-fluid"
                        alt=""
                      />
                      Category
                    </span>
                  </div>
                  <h3>
                    <a href="#">Responsive design: Cross-device experience</a>
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipiscing eli mattis
                    sit phasellus mollis sit aliquam sit nullam neque ultrices.
                  </p>
                </div>
              </div>
              <div class="helpblog-card">
                <div class="helpblog-desc">
                  <div class="helpblog-date">
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/calendar.png`}
                        class="img-fluid"
                        alt=""
                      />
                      Jan 22, 2024
                    </span>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/tag.png`}
                        class="img-fluid"
                        alt=""
                      />
                      Category
                    </span>
                  </div>
                  <h3>
                    <a href="#">Web design best practices: Optimizing speed</a>
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipiscing eli mattis
                    sit phasellus mollis sit aliquam sit nullam neque ultrices.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="helpblog-extra">
        <div class="container">
          <div class="row">
            <div class="col-md-4">
              <div class="helpblog-card">
                <div class="helpblog-img">
                  <a href="#">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/helpblog2.jpg`}
                      class="img-fluid"
                      alt=""
                    />
                  </a>
                </div>
                <div class="helpblog-desc">
                  <div class="helpblog-date">
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/calendar.png`}
                        class="img-fluid"
                        alt=""
                      />
                      Jan 24, 2024
                    </span>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/tag.png`}
                        class="img-fluid"
                        alt=""
                      />
                      Category
                    </span>
                  </div>
                  <h3>
                    <a href="#">
                      Typography in web design: Enhancing UI/UX web apps
                    </a>
                  </h3>
                  <a href="#" class="btn btn-helpblog">
                    Read more
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/arrowrigth.png`}
                      class="img-fluid"
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="helpblog-card">
                <div class="helpblog-img">
                  <a href="#">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/helpblog2.jpg`}
                      class="img-fluid"
                      alt=""
                    />
                  </a>
                </div>
                <div class="helpblog-desc">
                  <div class="helpblog-date">
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/calendar.png`}
                        class="img-fluid"
                        alt=""
                      />
                      Jan 24, 2024
                    </span>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/tag.png`}
                        class="img-fluid"
                        alt=""
                      />
                      Category
                    </span>
                  </div>
                  <h3>
                    <a href="#">
                      Typography in web design: Enhancing UI/UX web apps
                    </a>
                  </h3>
                  <a href="#" class="btn btn-helpblog">
                    Read more
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/arrowrigth.png`}
                      class="img-fluid"
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="helpblog-card">
                <div class="helpblog-img">
                  <a href="#">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/helpblog2.jpg`}
                      class="img-fluid"
                      alt=""
                    />
                  </a>
                </div>
                <div class="helpblog-desc">
                  <div class="helpblog-date">
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/calendar.png`}
                        class="img-fluid"
                        alt=""
                      />
                      Jan 24, 2024
                    </span>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/tag.png`}
                        class="img-fluid"
                        alt=""
                      />
                      Category
                    </span>
                  </div>
                  <h3>
                    <a href="#">
                      Typography in web design: Enhancing UI/UX web apps
                    </a>
                  </h3>
                  <a href="#" class="btn btn-helpblog">
                    Read more
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/arrowrigth.png`}
                      class="img-fluid"
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="helpblog-card">
                <div class="helpblog-img">
                  <a href="#">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/helpblog2.jpg`}
                      class="img-fluid"
                      alt=""
                    />
                  </a>
                </div>
                <div class="helpblog-desc">
                  <div class="helpblog-date">
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/calendar.png`}
                        class="img-fluid"
                        alt=""
                      />
                      Jan 24, 2024
                    </span>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/tag.png`}
                        class="img-fluid"
                        alt=""
                      />
                      Category
                    </span>
                  </div>
                  <h3>
                    <a href="#">
                      Typography in web design: Enhancing UI/UX web apps
                    </a>
                  </h3>
                  <a href="#" class="btn btn-helpblog">
                    Read more
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/arrowrigth.png`}
                      class="img-fluid"
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="helpblog-card">
                <div class="helpblog-img">
                  <a href="#">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/helpblog2.jpg`}
                      class="img-fluid"
                      alt=""
                    />
                  </a>
                </div>
                <div class="helpblog-desc">
                  <div class="helpblog-date">
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/calendar.png`}
                        class="img-fluid"
                        alt=""
                      />
                      Jan 24, 2024
                    </span>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/tag.png`}
                        class="img-fluid"
                        alt=""
                      />
                      Category
                    </span>
                  </div>
                  <h3>
                    <a href="#">
                      Typography in web design: Enhancing UI/UX web apps
                    </a>
                  </h3>
                  <a href="#" class="btn btn-helpblog">
                    Read more
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/arrowrigth.png`}
                      class="img-fluid"
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="helpblog-card">
                <div class="helpblog-img">
                  <a href="#">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/helpblog2.jpg`}
                      class="img-fluid"
                      alt=""
                    />
                  </a>
                </div>
                <div class="helpblog-desc">
                  <div class="helpblog-date">
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/calendar.png`}
                        class="img-fluid"
                        alt=""
                      />
                      Jan 24, 2024
                    </span>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/tag.png`}
                        class="img-fluid"
                        alt=""
                      />
                      Category
                    </span>
                  </div>
                  <h3>
                    <a href="#">
                      Typography in web design: Enhancing UI/UX web apps
                    </a>
                  </h3>
                  <a href="#" class="btn btn-helpblog">
                    Read more
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/arrowrigth.png`}
                      class="img-fluid"
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="helpblog-card">
                <div class="helpblog-img">
                  <a href="#">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/helpblog2.jpg`}
                      class="img-fluid"
                      alt=""
                    />
                  </a>
                </div>
                <div class="helpblog-desc">
                  <div class="helpblog-date">
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/calendar.png`}
                        class="img-fluid"
                        alt=""
                      />
                      Jan 24, 2024
                    </span>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/tag.png`}
                        class="img-fluid"
                        alt=""
                      />
                      Category
                    </span>
                  </div>
                  <h3>
                    <a href="#">
                      Typography in web design: Enhancing UI/UX web apps
                    </a>
                  </h3>
                  <a href="#" class="btn btn-helpblog">
                    Read more
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/arrowrigth.png`}
                      class="img-fluid"
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="helpblog-card">
                <div class="helpblog-img">
                  <a href="#">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/helpblog2.jpg`}
                      class="img-fluid"
                      alt=""
                    />
                  </a>
                </div>
                <div class="helpblog-desc">
                  <div class="helpblog-date">
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/calendar.png`}
                        class="img-fluid"
                        alt=""
                      />
                      Jan 24, 2024
                    </span>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/tag.png`}
                        class="img-fluid"
                        alt=""
                      />
                      Category
                    </span>
                  </div>
                  <h3>
                    <a href="#">
                      Typography in web design: Enhancing UI/UX web apps
                    </a>
                  </h3>
                  <a href="#" class="btn btn-helpblog">
                    Read more
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/arrowrigth.png`}
                      class="img-fluid"
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="helpblog-card">
                <div class="helpblog-img">
                  <a href="#">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/helpblog2.jpg`}
                      class="img-fluid"
                      alt=""
                    />
                  </a>
                </div>
                <div class="helpblog-desc">
                  <div class="helpblog-date">
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/calendar.png`}
                        class="img-fluid"
                        alt=""
                      />
                      Jan 24, 2024
                    </span>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/tag.png`}
                        class="img-fluid"
                        alt=""
                      />
                      Category
                    </span>
                  </div>
                  <h3>
                    <a href="#">
                      Typography in web design: Enhancing UI/UX web apps
                    </a>
                  </h3>
                  <a href="#" class="btn btn-helpblog">
                    Read more
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/arrowrigth.png`}
                      class="img-fluid"
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="helpform">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <h3>Still Facing Issues?</h3>
              <div class="location-right">
                <form>
                  <div class="form-group">
                    <label>Full Name*</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Active State"
                      id=""
                    />
                    <span class="input-info">Enter your Full Name</span>
                  </div>
                  <div class="form-group">
                    <label>Phone Number*</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Active State"
                      id=""
                    />
                    <span class="input-info">Enter your Mobile Number</span>
                  </div>
                  <div class="form-group">
                    <label>Email*</label>
                    <input
                      type="email"
                      class="form-control"
                      placeholder="Active State"
                      id="email"
                    />
                    <span class="input-info">Enter your official EMAIL ID</span>
                  </div>
                  <div class="form-group">
                    <label>Message*</label>
                    <textarea
                      class="form-control"
                      rows="5"
                      id="comment"
                      name="text"
                      placeholder="Active State"
                    ></textarea>
                    <span class="input-info">
                      Write your detailed message here
                    </span>
                  </div>
                  <div class="form-group">
                    <div class="recaptcha">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/reCAPTCHA.png`}
                        class="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <button type="submit" class="btn btn-info">
                    Send
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/sendicon.svg`}
                      class="img-fluid"
                      alt=""
                    />
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default helpdisk;
